import FirestoreModel from './FirestoreModel'

class EmailTemplate extends FirestoreModel {
  constructor(firestore, collection) {
    super(firestore)
    this.setCollection(collection)
  }

  getDefaultEmailTemplate = async (id) => {
    try {
      const query = this.firestore().collection('defaultEmailTemplates')
      const docRef = await query.doc(id).get()

      return docRef.data()
    } catch (e) {
      return null
    }
  }

  getDefaultTemplates = async (lang) => {
    const query = this.firestore().collection('defaultEmailTemplates')
    const snapshot = await query.get()
    let templates = []
    snapshot.forEach((doc, index) => {
      const data = doc.data()
      if (lang === 'en' && data.name.match(/^[a-z\s]+$/i)) {
        templates.push({ id: doc.id, ...data })
      }

      if (lang === 'ja' && !data.name.match(/^[a-z\s]+$/i)) {
        templates.push({ id: doc.id, ...data })
      }
    })

    return _.orderBy(templates, ['name'], ['asc'])
  }

  copyToDefaultTemplates = async () => {
    const query = this.firestore().collection('emailTemplates')
    const templates = await query
      .where('userId', '==', 'EEazi8xNIHbn3fWUx3I49jvOGBZ2')
      .get()

    const defaultTemplates = this.firestore().collection(
      'defaultEmailTemplates'
    )
    const defaultTemplateSnapshot = await defaultTemplates.get()

    //Delete defaultEmail templates
    defaultTemplateSnapshot.forEach((doc, index) => {
      doc.ref.delete()
    })

    for (let doc of templates.docs.values()) {
      const docRef = await defaultTemplates.add(doc.data())
      await docRef.update({ id: docRef.id })
    }
  }
}

export default EmailTemplate
