import classnames from 'classnames'
import Head from 'next/head'
import Link from 'next/link'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Col, Collapse, Row } from 'reactstrap'

import Header from './Header'

const Navbar = (props) => {
  const { t, i18n } = useTranslation()
  const { isDashboard, setIsDashboard } = useState(false)
  const { appState, setAppState } = useState(false)
  const classNameForNav = classnames({
    'navbar navbar-light topnav-menu': true,
    'justify-content-between': props.justifyContentBetween
  })
  return (
    <>
      <div className="topnav d-flex align-items-center px-0">
        <div
          className={`${
            props.withFluidContainer ? 'container-fluid  px-5' : 'container'
          }`}
        >
          <nav id="navigation" className="h-100">
            <div className="justify-content-center align-items-center h-100">
              <div className={classNameForNav}>{props.children}</div>
            </div>
          </nav>
        </div>
      </div>
    </>
  )
}

export default Navbar
