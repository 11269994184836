import Link from 'next/link'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Col, Row } from 'reactstrap'

import { helpPageUrl } from '../lib/helpers'
import { useAuth } from '../providers/AuthProvider'
import { useModal } from '../providers/ModalProvider'
import { usePlatform } from '../providers/PlatformProvider'
import { useWorkspace } from '../providers/WorkspaceProvider'
import LanguageDropdown from './LanguageDropdown'
import ProfileMenu from './ProfileMenu'
import PromolayerLogo from './common/PromolayerLogo'
import UpgradeModal from './common/UpgradeModal'

const Header = () => {
  const router = useRouter()
  const { t } = useTranslation()

  const { viewCountAndLimit, currentPlan } = useAuth()
  const { platform, goPricePage } = usePlatform()
  const { isWorkspaceOwner } = useWorkspace()
  const { showModal, hideModal } = useModal()

  const showUpgradeModal = (e) => {
    e.preventDefault()
    showModal({
      hideFooter: true,
      hideHeader: true,
      customPadding: '0 30px',
      innerComponent: <UpgradeModal hideModal={hideModal} platform={platform} />
    })
  }

  const canUse = () => {
    return !isWorkspaceOwner() || currentPlan !== 'Free'
  }

  const usageWarningBar = useMemo(() => {
    if (viewCountAndLimit) {
      let { limit, viewCount } = viewCountAndLimit

      if (limit > 0) {
        const usageRate = (viewCount / limit) * 100
        if (usageRate >= 100) {
          return (
            <div className="bg-danger text-white text-center p-3">
              <Row className="align-items-center align-content-center justify-content-center">
                <Col className="pb-1 pb-sm-0" sm={1}>
                  <i className="mdi mdi-alert-outline mdi-24px" />
                </Col>
                <Col className="pb-3 pb-sm-0" sm={9}>
                  {platform.type === 'colorme' ? (
                    <strong>
                      月間表示回数の上限に達しております。
                      <br />
                      カラーミーショップではアップグレード機能がございませんのでお手数ですが、一度アンインストールしてから、再インストール時に上位プランをお選びください
                    </strong>
                  ) : (
                    <strong>
                      {t(
                        'You have reached 100% of your view limit. Displays are stopped.'
                      )}
                      <br />
                      {t('Upgrade now to restart displays.')}
                    </strong>
                  )}
                </Col>
                <Col sm={2}>
                  <button
                    onClick={goPricePage}
                    className="btn btn-hollow-custom"
                  >
                    {t('Upgrade')}
                  </button>
                </Col>
              </Row>
            </div>
          )
        } else if (usageRate > 80) {
          return (
            <div className="bg-warning text-white text-center p-3">
              <Row className="align-items-center align-content-center justify-content-center">
                <Col className="pb-1 pb-sm-0" sm={1}>
                  <i className="mdi mdi-alert-outline mdi-24px" />
                </Col>
                <Col className="pb-3 pb-sm-0" sm={9}>
                  <strong>
                    {t(
                      'You have reached 80% of your view limit. Displays will be paused soon.'
                    )}
                    <br />
                    {t('Upgrade now to avoid missing out on conversions.')}
                  </strong>
                </Col>
                <Col sm={2}>
                  <button
                    onClick={goPricePage}
                    className="btn btn-hollow-custom"
                  >
                    {t('Upgrade')}
                  </button>
                </Col>
              </Row>
            </div>
          )
        } else {
          return null
        }
      }
    } else return null
  }, [viewCountAndLimit])

  return (
    <header id="page-topbar">
      <nav className="navbar-header navbar-dark px-1 px-lg-5 d-flex align-items-center">
        <div
          className="logo-wrapper d-none d-xxl-flex"
          style={{ flexBasis: '165px' }}
        >
          <PromolayerLogo privateRoute={true} />
        </div>
        <div className="navbar-nav d-flex">
          <div className="navbar-main-menu">
            <div className="d-flex w-100">
              <Link href="/displays" legacyBehavior>
                <a
                  className={`nav-link d-flex align-items-center me-2 ${
                    router.pathname === '/displays' && 'active'
                  }`}
                >
                  <i className="mdi mdi-monitor-star mdi-18px me-lg-2 d-none d-xl-block" />
                  <span className="text-nowrap">{t('Displays')}</span>
                </a>
              </Link>
              <Link href="/ab-test" legacyBehavior>
                  <div className="position-relative">
                    {!canUse() && ( <i className="mdi mdi-crown upgrade-crown" /> )}
                    <a onClick={(e) => !canUse() ? showUpgradeModal(e) : null}
                      className={`nav-link with-badge d-flex align-items-center me-2 ${
                        router.pathname === '/ab-test' && 'active'
                      }`}
                    >
                      <i className="mdi mdi-flask mdi-18px me-lg-2 d-none d-xl-block" />
                      <span className="text-nowrap">{t('A/B test')}</span>
                    </a>
                  </div>
              </Link>
              <Link href="/lists" legacyBehavior>
                <a
                  className={`nav-link d-flex align-items-center me-2 ${
                    router.pathname === '/lists' && 'active'
                  }`}
                >
                  <i className="mdi mdi-view-list-outline mdi-18px  me-lg-2 d-none d-xl-block" />
                  <span className="text-nowrap">{t('Lists')}</span>
                </a>
              </Link>
              <Link
                href={
                  platform.supportsGeneratingCoupons
                    ? '/coupon-histories'
                    : '/coupons'
                }
                legacyBehavior
              >
                <a
                  className={`nav-link d-flex align-items-center me-2 ${
                    router.pathname === '/coupons' && 'active'
                  } ${router.pathname === '/coupon-histories' && 'active'}`}
                >
                  <i className="mdi mdi-ticket-percent-outline mdi-18px  me-lg-2 d-none d-xl-block" />
                  <span className="text-nowrap">
                    {platform.supportsGeneratingCoupons
                      ? t('Coupon history')
                      : t('Coupons')}
                  </span>
                </a>
              </Link>
              <Link href="/email-templates" legacyBehavior>
                <div className="position-relative">
                {!canUse() && ( <i className="mdi mdi-crown upgrade-crown" /> )}
                  <a onClick={(e) => !canUse() ? showUpgradeModal(e) : null}
                    className={`nav-link d-flex align-items-center me-2 ${
                      router.pathname === '/email-templates' && 'active'
                    }`}
                  >
                    <i className="mdi mdi-email-edit-outline mdi-18px  me-lg-2 d-none d-xl-block" />
                    <span className="">{t('Email templates')}</span>
                  </a>
                </div>
              </Link>
              <a
                className="nav-link align-items-center d-flex search-help-icon"
                href={helpPageUrl('', '', true)}
                target="_blank"
              >
                <i className="mdi mdi-help mdi-18px  me-lg-2 d-none d-xl-block" />{' '}
                <span className="">{t('Help')}</span>
              </a>
            </div>
          </div>
          <div className="navbar-user-menu">
            <>
              {currentPlan === 'Free' && (
                <a onClick={goPricePage} className="d-none d-lg-block">
                  <Badge
                    color="warning"
                    className="p-2 me-3 text-white"
                    style={{ textTransform: 'capitalize' }}
                  >
                    {t('Upgrade')}
                  </Badge>
                </a>
              )}
              <a onClick={goPricePage} className="d-none d-lg-block">
                <Badge
                  color="info"
                  className="p-2 me-3"
                  style={{ textTransform: 'capitalize' }}
                >
                  {t(currentPlan)}
                </Badge>
              </a>

              <LanguageDropdown />
              <ProfileMenu goPricePage={goPricePage} />
            </>
          </div>
        </div>
      </nav>
      {usageWarningBar}

      <style jsx>
        {`
          .with-badge {
            position: relative;
          }

          .badge {
            position: absolute;
            top: -3px;
            left: 50%;
            transform: translate(-50%, 0);
          }
          .nav-link.nav-link.nav-link {
            padding-left: 10px;
            padding-right: 10px;
          }
          .nav-link.active {
            background: #856fcf;
            border-radius: 5px;
          }
        `}
      </style>
      <style jsx global>
        {`
          #usageIndicator .bx-bell::before {
            font-size: 1.5em;
            vertical-align: middle;
            padding-right: 5px;
          }

          .btn-hollow-custom {
            background-color: transparent;
            border: 1px solid #fff;
            color: #fff;
            cursor: pointer;
          }
          .upgrade-crown{
            color:#f1b44c;
            position: absolute;
            left:50%;
            transform: translate(-50%, -8px);
            font-size: 16px;
          }
          @media (max-width: 992px) {
            .nav-link{
              margin-right: 0 !important;
              padding-left:8px !important;
              padding-right:8px !important;
              padding-top:10px;
            }
          }
        `}
      </style>
    </header>
  )
}

export default Header
