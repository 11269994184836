import classnames from 'classnames'
import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

const ValidationContext = createContext()

const ValidationProvider = ({ children }) => {
  const { t } = useTranslation()
  const { pathname } = useRouter()
  const [initialized, setInitialized] = useState()
  const [errorMsg, setErrorMsg] = useState({
    required: t('This is required'),
    pattern: t('This is invalid'),
    validate: t('This name is duplicated')
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    setValue,
    getValues,
    reset,
    trigger
  } = useForm({
    mode: 'onChange'
  })

  useEffect(() => {
    reset()
    setInitialized(Date.now())
  }, [pathname])

  const showErrors = (id) => {
    const errorType = errors[id]?.type
    if (!errorType) return null

    return <span>{errorMsg[errorType]}</span>
  }

  const hasErrors = (id) => {
    if (id) return !!errors[id]
    else return Object.keys(errors).length > 0
  }

  const getClasses = (field) => {
    return classnames({
      'form-control': true,
      'is-invalid': hasErrors(field)
    })
  }

  return (
    <ValidationContext.Provider
      value={{
        showErrors,
        register,
        handleSubmit,
        hasErrors,
        errorMsg,
        setErrorMsg,
        errors,
        setValue,
        getValues,
        reset,
        getClasses,
        initialized,
        trigger
      }}
    >
      {children}
    </ValidationContext.Provider>
  )
}

const useValidation = () => useContext(ValidationContext)
export { useValidation, ValidationProvider }
