import FirestoreModel from './FirestoreModel'

class CouponPool extends FirestoreModel {
  constructor(firestore, collection) {
    super(firestore)
    this.setCollection(collection)
  }
}

export default CouponPool
