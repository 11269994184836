import { getDefaultFont } from './defaultFonts'

const workspaceDefault = {
  font: getDefaultFont('workspace'),
  useCustomFontFamily: false,
  customFontFamily: '',
  textColor: { r: 0, g: 0, b: 0, a: 1 },
  bgColor: { r: 255, g: 255, b: 255, a: 1 }
}

export default workspaceDefault
