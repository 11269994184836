import { getDefaultFont } from '../../lib/defaultFonts'
import componentsDefault from '../display-components/defaults/defaults-components'

export default {
  heading: {
    type: 'heading',
    title: 'Heading',
    alignment: 'center',
    alignmentMobile: 'center',
    headingTag: 'h2',
    headingTagMobile: 'h4',
    font: getDefaultFont('heading'),
    textColor: { r: 0, g: 0, b: 0, a: 1 },
    visibility: 'all'
  },
  text: {
    type: 'text',
    title: 'Text',
    visibility: 'all',
    font: getDefaultFont('text')
  },
  button: {
    type: 'button',
    buttonType: 'single',
    buttonStyle: 'btn',
    title: 'Button',
    alignSelf: 'auto',
    alignSelfMobile: 'auto',
    stretch: 'inline',
    stretchMobile: 'block',
    size: 'md',
    sizeMobile: 'md',
    buttonAction: 'link',
    buttonTwoAction: 'close',
    text: 'Button',
    buttonTwoText: 'Button2',
    link: 'https://',
    buttonTwoLink: 'https://',
    textColor: { r: 255, g: 255, b: 255, a: 1 },
    buttonTwoTextColor: { r: 255, g: 255, b: 255, a: 1 },
    buttonColor: { r: 92, g: 61, b: 194, a: 1 },
    buttonTwoColor: { r: 92, g: 61, b: 194, a: 1 },
    fontWeight: 'regular',
    font: getDefaultFont('button'),
    customFontFamily: '',
    useCustomFontFamily: false,
    buttonTwoFontWeight: 'regular',
    buttonTwoFont: getDefaultFont('button'),
    customButtonTwoFontFamily: '',
    useCustomButtonTwoFontFamily: false,
    visibility: 'all'
  },
  image: {
    type: 'image',
    title: 'Image',
    alignSelf: 'auto',
    alignSelfMobile: 'auto',
    imageUrl: '',
    mobileWidth: 100,
    desktopWidth: 100,
    visibility: 'all'
  },
  countdown: {
    type: 'countdown',
    title: 'Countdown',
    countdownType: 'specificTime',
    endDatetime: parseInt(Date.now() / 1000 + 60 * 60 * 48),
    font: getDefaultFont('countdown'),
    fontWeight: 'regular',
    labelFont: getDefaultFont('countdown'),
    labelFontWeight: 'regular',
    endMessage: 'Time is up',
    textColor: { r: 0, g: 0, b: 0, a: 1 },
    textBgColor: { r: 255, g: 255, b: 255, a: 0 },
    labelColor: { r: 51, g: 51, b: 51, a: 1 },
    endColor: { r: 51, g: 51, b: 51, a: 1 },
    visibility: 'all',
    countdownWidth: 'center'
  },
  sticker: {
    type: 'sticker',
    title: 'Sticker',
    ...componentsDefault.sticker
  },
  divider: {
    type: 'divider',
    title: 'Divider',
    visibility: 'all',
    thickness: 1,
    color: { r: 224, g: 224, b: 224, a: 1 }
  },
  spacer: {
    type: 'spacer',
    title: 'Spacer',
    visibility: 'all',
    size: 12,
    sizeMobile: 6
  },
  coupon: {
    type: 'coupon',
    title: 'Coupon',
    coupon: { couponCode: 'SAMPLE', couponType: 'static' },
    fontSize: 16,
    bgColor: { r: 255, g: 255, b: 255, a: 1 },
    textColor: { r: 0, g: 0, b: 0, a: 1 },
    borderColor: { r: 0, g: 0, b: 0, a: 1 },
    stretch: 'inline',
    stretchMobile: 'block'
  },
  html: {
    type: 'html',
    title: 'HTML',
    mobileWidth: 100,
    desktopWidth: 100
  },
  checkbox: {
    font: getDefaultFont('text')
  },
  formSettings: {
    type: 'form-settings',
    spaceBetweenFields: 'compact',
    font: getDefaultFont('text'),
    fontWeight: 'regular',
    useCustomFontFamily: false
  }
}
