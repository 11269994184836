import axios from 'axios'

let timeout = null

export async function purgeCacheEntity(
  userId,
  workspaceId,
  forceUsageUpdate,
  allWorkspaces,
  fromBrowser = false
) {
  const host = fromBrowser ? '' : process.env.NEXT_PUBLIC_LIVE_URL

  try {
    const res = await axios.post(`${host}/api/common/purgeCache`, {
      userId,
      workspaceId,
      allWorkspaces,
      forceUsageUpdate
    })
    if (!res.data.success) {
      console.error('Failed to refresh')
    }
  } catch (e) {
    console.error(e)
  }
}

export function purgeCache(
  userId,
  workspaceId,
  forceUsageUpdate = false,
) {
  let allWorkspaces = false

  if(!workspaceId) allWorkspaces = true

  const fromBrowser = typeof window !== 'undefined'
  if (fromBrowser && !allWorkspaces) {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(
      () =>
        purgeCacheEntity(
          userId,
          workspaceId,
          forceUsageUpdate,
          allWorkspaces,
          fromBrowser
        ),
      1000
    )
  } else {
    purgeCacheEntity(
      userId,
      workspaceId,
      forceUsageUpdate,
      allWorkspaces,
      fromBrowser
    ).catch((e) => {
      //console.log(e)
    })
  }
}
export const PLANS_ORDERED = [
  { rank: 0, name: 'free' },
  { rank: 1, name: 'micro' },
  { rank: 2, name: 'basic' },
  { rank: 3, name: 'standard' },
  { rank: 4, name: 'professional' },
  { rank: 5, name: 'enterprise' },
  { rank: 6, name: 'plus level 1' },
  { rank: 7, name: 'plus level 2' },
  { rank: 8, name: 'plus level 3' },
  { rank: 9, name: 'plus level 4' },
  { rank: 10, name: 'unlimited' }
]

export function isUpgrade(currentPlan, newPlan) {
  let activePlan = (currentPlan ?? 'Free').toLowerCase()
  const currentRank = PLANS_ORDERED.find(
    (plan) => plan.name === activePlan
  ).rank
  const newRank = PLANS_ORDERED.find(
    (plan) => plan.name === newPlan.toLowerCase()
  ).rank

  return currentRank < newRank
}
