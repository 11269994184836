import FirestoreModel from './FirestoreModel'

class CouponHistory extends FirestoreModel {
  constructor(firestore, collection) {
    super(firestore)
    this.setCollection(collection)
  }

  async getAllInWorkspace(workspace) {
    const displayIdsInWorkspace = [],
      rtn = []
    const displaysInWorkspace = await this.firestore()
      .collection('popups')
      .where('workspace', '==', workspace)
      .get()
    displaysInWorkspace.forEach((doc) => {
      displayIdsInWorkspace.push(doc.id)
    })

    let items
    while ((items = displayIdsInWorkspace.splice(0, 10))) {
      if (items.length === 0) break
      const snapshot = await this.firestore()
        .collection(this.collectionName)
        .where('displayId', 'in', items)
        .get()
      snapshot.forEach((doc) => {
        rtn.push({ ...doc.data(), id: doc.id })
      })
    }

    return rtn
  }

  async getAllOfDisplay(displayId) {
    let query = this.firestore().collection(this.collectionName)
    const rtn = []
    const snapshot = await query.where('displayId', '==', displayId).get()
    snapshot.forEach((doc) => {
      rtn.push({ ...doc.data(), id: doc.id })
    })
    return rtn
  }
}

export default CouponHistory
