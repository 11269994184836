import React, { useEffect } from 'react'

const ChromelessLayout = ({ children, error, title }) => {
  useEffect(() => {
    document.documentElement.style.minWidth = 'unset'
    document.body.style.minWidth = 'unset'
  }, [])
  return <>{error ? <>{error}</> : <>{children}</>}</>
}
export default ChromelessLayout
