import FirestoreModel from './FirestoreModel'

class Template extends FirestoreModel {
  constructor(firestore, collection) {
    super(firestore)
    this.setCollection(collection)
  }

  async getTemplatesByType(type) {
    let query = this.firestore().collection(this.collectionName)
    if (type) {
      query = query.where('type', '==', type)
    }
    const snapshot = await query.get()

    let templates = []

    snapshot.forEach((doc, index) => {
      const template = doc.data()
      template._ordering = parseInt(template._ordering)
      if (Number.isNaN(template._ordering)) template._ordering = -100
      templates.push({ id: doc.id, ...template })
    })

    return templates
  }
}

export default Template
