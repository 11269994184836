import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import Cookies from 'js-cookie'
import orderBy from 'lodash/orderBy'

import { detectLang } from '../lib/helpers'
import measurementHelper from './apiHelpers/measurementHelper'

const firestore = firebase.firestore

class FirebaseHandler {
  user = null
  setUserData = null
  sentRegistration = false
  workspace = {}
  getFirestore = () => {
    return firestore
  }

  getUser = () => {
    return new Promise((resolve, reject) => {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          resolve(user)
        } else {
          resolve(false)
        }
      })
    })
  }
  /**
   * Registers the user with given details
   */
  registerUser = (email, password, workspace = null) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(
          (data) => {
            if (data.user?.uid) {
              this.addNewUserToFirestore(data.user, workspace).then(
                (userData) => {
                  this.afterLogin(userData).then(() => {
                    resolve(data.user)
                  })
                }
              )
            }
          },
          (error) => {
            reject(error)
          }
        )
    })
  }

  sameEmailUser = async (email) => {
    try {
      const res = await axios.post('/api/accountCheck', { email })
      return res.data
    } catch (e) {
      console.log(e)
    }
  }

  /**
   * Login user with given details
   */
  loginUser = (email, password, workspace = null) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(
          async ({ user }) => {
            const userData = await this.getUserData(user.uid)
            if (workspace) {
              await this.addMemberToWorkspace(user, workspace)
            }

            await this.afterLogin(userData)
            resolve(user)
          },
          (error) => {
            reject(this._handleError(error))
          }
        )
    })
  }

  loginWithOAuth = (customToken) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithCustomToken(customToken)
        .then(
          async ({ user }) => {
            const userData = await this.getUserData(user.uid)
            if (userData) await this.afterLogin(userData, true)
            resolve(user)
          },
          (error) => {
            reject(this._handleError(error))
          }
        )
    })
  }

  //For impersonate
  loginWithCustomToken = (customToken) => {
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .signInWithCustomToken(customToken)
        .then(
          async ({ user }) => {
            // const userData = await this.getUserData(user.uid)
            // await this.afterLogin(userData)
            resolve(user)
          },
          (error) => {
            reject(this._handleError(error))
          }
        )
    })
  }

  afterLogin = async (userData, oauth) => {
    try {
      window.gtag('get', 'G-JZCXJ6JWLR', 'client_id', (clientId) => {
        const twitterClickId = Cookies.get('twclid') ?? ''
        this.updateUserField(userData.userId, {
          gaClientId: clientId ?? '',
          twitterClickId,
          firstLoggedInAt: new Date().toISOString()
        }).then(() => {
          const platform = userData.platform
            ? userData.platform.name
            : 'generic'
          if (!userData.firstLoggedInAt) {
            //registration
            if (typeof ytag === 'function') {
              window.ytag?.({
                type: 'yjad_conversion',
                config: {
                  yahoo_ydn_conv_io: '0Rn465JynDMC7BK47A58UQ..',
                  yahoo_ydn_conv_label: '7N0E3B9185JW2HO0491018246',
                  yahoo_ydn_conv_transaction_id: '',
                  yahoo_ydn_conv_value: '1000'
                }
              })
            }
            measurementHelper
              .send({
                google: {
                  clientId,
                  userId: userData.userId,
                  events: { name: 'sign_up', params: { platform } }
                },
                twitter: {
                  twitterClickId: Cookies.get('twclid'),
                  email: userData.email,
                  eventType: 'sign-up',
                  contents: [{ content_type: platform }]
                }
              })
              .catch(console.log)
          } else {
            measurementHelper
              .send({
                google: {
                  clientId,
                  userId: userData.userId,
                  events: { name: 'login', params: { platform } }
                },
                twitter: {}
              })
              .catch(console.log)
          }
        })
      })
    } catch (e) {
      console.error(e)
    }
  }

  /**
   * forget Password user with given details
   */
  forgetPassword = (email) => {
    firebase.auth().languageCode = detectLang()
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(() => {
          resolve(true)
        })
        .catch((error) => {
          reject(this._handleError(error))
        })
    })
  }

  removeAuthCookies = async () => {
    fetch('/api/logout').catch((e) => {
      console.error(e)
    })
  }
  unsubscribeSnapshot = async () => {
    this.listening = false
    if (this.unsubscribe) await this.unsubscribe()
  }
  /*
   * Logout the user
   */
  logout = () => {
    return new Promise(async (resolve, reject) => {
      await this.unsubscribeSnapshot()
      //await this.removeAuthCookies()
      firebase
        .auth()
        .signOut()
        .then(async () => {
          resolve(true)
        })
        .catch((error) => {
          reject(this._handleError(error))
        })
    })
  }

  addNewUserToFirestore = async (user, workspace) => {
    const collection = firestore().collection('users')

    const details = {
      userId: user.uid,
      firstName: '',
      lastName: '',
      fullName: user.email?.split('@')[0] ?? '',
      email: user.email,
      platform: { name: 'generic', currentPlan: 'Free' },
      createdDtm: new Date(),
      createdAt: new Date().toISOString(),
      preferredLanguage: detectLang()
    }

    const data = { ...details }

    await collection.doc(user.uid).set(data)

    if (workspace) {
      await this.addMemberToWorkspace(user, workspace)
    }

    return data
  }

  setLoggeedInUser = (user, userData) => {
    localStorage.setItem('authUser', JSON.stringify(user))
    localStorage.setItem('userData', JSON.stringify(userData))
  }

  /**
   * Returns the authenticated user
   */
  getAuthenticatedUser = () => {
    if (!localStorage.getItem('authUser')) return null
    return JSON.parse(localStorage.getItem('authUser'))
  }

  updatePreview = async (id, display) => {
    const cloned = { ...display }
    cloned.updatedAt = new Date().toISOString()
    cloned.previewedAt = new Date().toISOString()
    const docRef = firestore().collection('previews').doc(id)
    await docRef.set(cloned, { merge: true })
  }

  //This is for users
  updateUserField = async (userId, updates) => {
    const docRef = firestore().collection('users').doc(userId)
    await docRef.set(updates, { merge: true })
    return true
  }

  onFinishInstruction = async () => {
    try {
      await this.updateUserField(this.user.id, { finishedInstruction: true })
    } catch (e) {
      console.error(e)
    }
  }

  processScreenShot = async (display, template) => {
    return await axios.post(
      `${process.env.NEXT_PUBLIC_SCREENSHOT_HOST}/screenshot`,
      { display, template }
    )
  }

  canEdit = async (display) => {
    const docRef = firestore().collection('workspaces').doc(display.workspace)
    const doc = await docRef.get()
    const workspace = doc.data()
    return (
      workspace.userId === this.user.id ||
      workspace.memberEmails.includes(this.user.email)
    )
  }

  ruleTest = async (id) => {
    try {
      const docRef = firestore().collection('displays').doc(id)
      await docRef.delete()
      return [true, null]
    } catch (e) {
      return [null, e]
    }
  }

  getUserDataDocRef = async (userId) => {
    userId = userId || this.user.id
    const query = await firestore().collection('users')
    return query.doc(userId)
  }

  getUserData = async (userId) => {
    userId = userId || this.user.id
    const docRef = await this.getUserDataDocRef(userId)
    const doc = await docRef.get()
    const data = await doc?.data()
    return data
  }

  listenUserDataChanges = (userId, callback) => {
    if (!this.listening) {
      this.listening = true
      this.unsubscribe = firestore()
        .collection('users')
        .where('userId', '==', userId)
        .onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            if (change.type === 'modified') {
              callback(change.doc.data())
            } else if (change.type === 'removed') {
              callback(false)
            }
          })
          //callback()
        })
      return this.unsubscribe
    }
  }

  //This is called when every time user logged in
  updateUserData = async (userData) => {
    const query = await firestore().collection('users')
    const doc = await query.doc(userData.userId)
    await doc.set(userData, { merge: true })
  }

  getCurrentLanguage = () => {
    return detectLang()
  }

  updateUserDataState = (userData) => {
    if (this.setUserData) {
      this.setUserData({ ...userData })
    }
  }

  setWorkspace = (workspace) => {
    this.workspace = workspace
  }

  addMemberToWorkspace = async (user, workspace) => {
    try {
      await axios.post('/api/workspace/addMember', {
        userId: user.uid,
        email: user.email,
        workspaceId: workspace.id
      })
    } catch (e) {
      console.log(e)
    }
  }

  saveSubscriptionPlan = async (data, id) => {
    const query = await firestore().collection('subscriptionPlans')

    const ref = query.doc(id)
    return ref.set(data, { merge: true })
  }

  copySubscriptionPlans = async (fromId) => {
    const fromRef = await firestore()
      .collection('subscriptionPlans')
      .doc(fromId)
    const fromDoc = await fromRef.get()
    await firestore().collection('subscriptionPlans').add(fromDoc.data())
  }

  getSubscriptionPlans = async (platform, withFree) => {
    const query = await firestore().collection('subscriptionPlans')
    const snapshot = await query.where('platform', '==', platform).get()
    let plans = []
    if (withFree) {
      plans.push(await this.getFreePlan())
    }
    snapshot.forEach((doc, index) => {
      plans.push({ id: doc.id, ...doc.data() })
    })
    return orderBy(plans, ['ordering'], ['asc'])
  }

  getFreePlan = async () => {
    const query = await firestore().collection('subscriptionPlans')
    const snapshot = await query.where('platform', '==', 'free').get()
    const doc = snapshot.docs[0]
    const data = await doc.data()
    return { id: doc.id, ...data }
  }

  getFileNameFromUrl = (url) => {
    if (/^https:\/\/firebasestorage/.test(url)) {
      const regex = new RegExp(
        /firebasestorage.googleapis.com\/(.*%2F){2}([^?]*)/
      )
      const res = regex.exec(url)
      return res && res[2]
    }
    return null
  }

  getTemplateThumbnail = async (id) => {
    const storage = await firebase.storage()
    const storageRef = await storage.ref(`templateThumbnails/${id}.jpg`)
    return storageRef.getDownloadURL()
  }

  setUserHandler = (user, setUserData) => {
    this.user = user
    this.setUserData = setUserData
  }

  cloneSubscriptionPlans = async (fromPlatform, toPlatform) => {
    const plans = await this.getSubscriptionPlans(fromPlatform)

    plans.forEach((item) => {
      const doc = firestore().collection('subscriptionPlans').doc()
      item.platform = toPlatform
      doc.set(item)
    })
  }

  async ruleTester(userId) {
    try {
      const snapshot = await firestore()
        .collection('emailListCustom')
        .where('workspace', '==', 'qrAQpGEiSWKNgHl5B4E1')
        .get()
      const rtn = []
      await snapshot.forEach((doc) => {
        rtn.push(doc.data())
      })
      return rtn
    } catch (e) {
      console.log('Test failed', e)
    }
  }

  sendDataToWordpress = async (id, url, secret) => {
    const ajaxRoute = `${url}/wp-admin/admin-ajax.php?action=promolayer_webhook`
    await axios.post(ajaxRoute, { id, secret })
  }

  getAuthUserByEmail = async (email) => {
    const response = await axios.post('/api/accountCheck', {
      email,
      firebaseAuth: true
    })
    return response.data
  }

  sendRegisterLink = async (email, workspaceId) => {
    const exists = await this.getAuthUserByEmail(email)
    const path = exists ? 'login' : 'register'

    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: `${
        process.env.NEXT_PUBLIC_LIVE_URL
      }/${path}_invitation?workspace=${workspaceId}&invitedEmail=${encodeURIComponent(
        email
      )}`,
      // This must be true.
      handleCodeInApp: true
    }
    try {
      firebase.auth().languageCode = detectLang()
      const result = await firebase
        .auth()
        .sendSignInLinkToEmail(email, actionCodeSettings)
      return [result, null]
    } catch (e) {
      return [null, e]
    }
  }

  isSignInWithEmailLink = (url) => {
    return firebase.auth().isSignInWithEmailLink(url)
  }
  signInWithEmailLink = async (email, url) => {
    return await firebase.auth().signInWithEmailLink(email, url)
  }

  async sendDebugData(data) {
    await firestore().collection('debugData').add(data)
  }

  /**
   * Handle the error
   * @param {*} error
   */
  _handleError(error) {
    // var errorCode = error.code;
    var errorMessage = error.message
    return errorMessage
  }
}

let _fireBaseHandler = null

/**
 * Returns the firebase backend
 */
const getFirebaseHandler = () => {
  if (!_fireBaseHandler) {
    _fireBaseHandler = new FirebaseHandler()
  }
  return _fireBaseHandler
}

/**
 * Returns unique ID
 */
export const uniqueId = function () {
  return (
    '_' + Date.now().toString(32) + Math.random().toString(36).substr(2, 10)
  )
}

export { getFirebaseHandler }
