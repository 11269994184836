import React from 'react'
import { useTranslation } from 'react-i18next'

import Spinner from './common/Spinner'

const styles = {
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  }
}

const FullPageLoader = () => {
  const { t, i18n } = useTranslation()

  return (
    <div style={styles.container}>
      <h3>{t('Loading')}...</h3>
      <Spinner color={{ r: 57, b: 57, g: 57, a: 1 }} />
    </div>
  )
}

export default FullPageLoader
