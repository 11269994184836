import FirestoreModel from './FirestoreModel'

class Workspace extends FirestoreModel {
  constructor(firestore, collection) {
    super(firestore)
    this.setCollection(collection)
  }

  async getInvited(invitedWorkspaces, userId) {
    //Merge invited workspaces
    let items,
      clonedArray = [...invitedWorkspaces],
      rtn = []
    const collection = this.firestore().collection(this.collectionName)
    while ((items = clonedArray.splice(0, 10))) {
      if (items.length === 0) break
      const snapshot = await collection
        .where(this.firestore.FieldPath.documentId(), 'in', items)
        .get()
      snapshot.forEach((doc) => {
        const data = doc.data()
        if (data.memberIds.includes(userId))
          rtn.push({ id: doc.id, invited: true, ...doc.data() })
      })
    }
    return rtn
  }
}

export default Workspace
