import FirestoreModel from './FirestoreModel'

class ABTest extends FirestoreModel {
  constructor(firestore, collection) {
    super(firestore)
    this.setCollection(collection)
  }

  async getManagedDisplays(abTestId) {
    const snapshot = await this.firestore()
      .collection('popups')
      .where('abTest', '==', abTestId)
      .get()
    const rtn = []
    snapshot.forEach((doc) => {
      const displayData = doc.data()
      displayData.contents =
        displayData.contents?.filter((c) => c.type === 'coupon') ?? []
      displayData.thankYouContents =
        displayData.thankYouContents?.filter((c) => c.type === 'coupon') ?? []
      delete displayData.forms
      delete displayData.showToUsersWho
      rtn.push({ ...displayData, id: doc.id })
    })

    return rtn
  }

  async update(data) {
    const cloned = { ...data }
    const displays = cloned.displays
    cloned.displays = []

    const updated = await super.update(cloned)
    updated.displays = displays
    return updated
  }
}

export default ABTest
