import FirestoreModel from './FirestoreModel'

class ApiKey extends FirestoreModel {
  constructor(firestore, collection) {
    super(firestore)
    this.setCollection(collection)
  }

  async getKeyData(userId) {
    const collection = this.firestore().collection(this.collectionName)
    const snapshot = await collection.where('userId', '==', userId).get()
    const doc = snapshot.docs[0]
    return doc.data()
  }
}

export default ApiKey
