import IdleTracker from 'idle-tracker'
import { useEffect, useState } from 'react'

const timeout = 30 * 1000 // 30 seconds

const useIdle = () => {
  const [isIdle, setIsIdle] = useState(false)
  //Set idle tracker
  useEffect(() => {
    const idleTracker = new IdleTracker({
      timeout: timeout,
      onIdleCallback: (state) => setIsIdle(state.idle)
    })
    idleTracker.start()
    return () => idleTracker.end()
  }, [])
  return { isIdle }
}

export { useIdle }
