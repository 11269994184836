import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'

const RefreshOnUpdatedBanner = () => {
  const { t } = useTranslation()
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#fff',
        backgroundColor: '#F46A6A',
        borderColor: '#F46A6A',
        height: 'auto',
        textAlign: 'center',
        padding: '10px 20px'
      }}
    >
      <h3>
        {t(
          'Promolayer was updated 🎉 Please refresh your browser to keep working'
        )}
      </h3>
      <Button
        color="info"
        size="md"
        onClick={() => {
          location.reload()
        }}
      >
        {t('Browser refresh')}
      </Button>
    </div>
  )
}

export default RefreshOnUpdatedBanner
