import React from 'react'
import { Modal, ModalBody, ModalHeader } from 'reactstrap'

const InnerModal = ({ toggle, modal, title, size = 'md', children }) => {
  return (
    <Modal
      isOpen={modal}
      size={size}
      backdrop={true}
      toggle={toggle}
      scrollable={true}
    >
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  )
}

export default InnerModal
