import { get, map } from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'

//i18n
import languages from '../languages'
import { useAuth } from '../providers/AuthProvider'
import { useFirebase } from '../providers/FirebaseProvider'

const LanguageDropdown = () => {
  const { i18n } = useTranslation()
  // Declare a new state variable, which we'll call "menu"
  const { userData } = useAuth() ?? {}
  const { firebase } = useFirebase()

  const [selectedLang] = useState('ja')
  const [menu, setMenu] = useState(false)

  const changeLanguageAction = (lang) => {
    //set language as i18n
    i18n.changeLanguage(lang)
    //save the lang
    localStorage.setItem('i18nextLng', lang)
    if (userData && firebase) {
      firebase.updateUserData({ ...userData, preferredLanguage: lang })
    }

    //Send child iframe
    const childFrameObj = document.getElementById('livePreview')

    if (childFrameObj) {
      childFrameObj.contentWindow?.postMessage({ lang: lang }, '*') //window.postMessage
    }
  }

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
        <DropdownToggle className="btn waves-effect" tag="button">
          <img
            src={get(languages, `${i18n.language}.flag`)}
            alt="Flag"
            height={16}
            width={24}
          />
        </DropdownToggle>
        <DropdownMenu className="language-switch" end>
          {map(Object.keys(languages), (key) => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${
                selectedLang === key ? 'active' : 'none'
              }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Flag"
                className="me-1"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default LanguageDropdown
