import Link from 'next/link'
import React from 'react'

import LanguageDropdown from './LanguageDropdown'
import PromolayerLogo from './common/PromolayerLogo'

const PublicHeader = () => {
  return (
    <header id="page-topbar">
      <nav className="navbar-header navbar-dark d-flex justify-content-between align-items-center px-5">
        <div
          className="logo-wrapper d-none d-xxl-flex"
          style={{ flexBasis: '165px' }}
        >
          <PromolayerLogo />
        </div>
        <div className="navbar-user-menu pe-2 pe-xl-0">
          <LanguageDropdown />
        </div>
      </nav>

      <style jsx>
        {`
          .with-badge {
            position: relative;
          }

          .badge {
            position: absolute;
            top: -3px;
            left: 50%;
            transform: translate(-50%, 0);
          }
        `}
      </style>
      <style jsx global>
        {`
          #usageIndicator .bx-bell::before {
            font-size: 1.5em;
            vertical-align: middle;
            padding-right: 5px;
          }

          .btn-hollow-custom {
            background-color: transparent;
            border: 1px solid #fff;
            color: #fff;
            cursor: pointer;
          }
        `}
      </style>
    </header>
  )
}

export default PublicHeader
