import FirestoreModel from './FirestoreModel'

class Display extends FirestoreModel {
  constructor(firestore, collection) {
    super(firestore)
    this.setCollection(collection)
  }

  async getAllInUser(userId, withoutABTest = true) {
    const displays = await super.getAllInUser(userId)
    if (withoutABTest) {
      return displays.filter((d) => !d.abTest)
    }
    return displays
  }

  async getAllInWorkspace(workspace, withoutABTest = true) {
    const displays = await super.getAllInWorkspace(workspace)
    if (withoutABTest) {
      return displays.filter((d) => !d.abTest)
    }
    return displays
  }

  async duplicate(displayId, abTest, testToLive = null, keepTitle = false) {
    const display = await this.get(displayId)
    if (testToLive) {
      testToLive.shouldTheDisplayRecur.showAgainAfter =
        display.shouldTheDisplayRecur.showAgainAfter
      Object.assign(display, testToLive)

      delete display.useControl
      delete display.name
      delete display.description
      delete display.abTest
      delete display.isABTest
      delete display.enabled
      delete display.startedAt
      delete display.finishedAt
      delete display.displays

      display.usePageTargetV2 = !!testToLive.usePageTargetV2
      display.published = false
    } else if (abTest) {
      display.whatTimeFrameToShow = {}
      display.useSchedule = false
      display.whenToShow = {}
      display.showOnPages = []
      display.dontShowOnPages = []
      display.showToUsersWho = {}
      display.publishedBefore = true
      display.abTest = abTest
      display.shouldTheDisplayRecur = {
        showAgainAfter: display.shouldTheDisplayRecur.showAgainAfter
      }
    }
    delete display.id
    display.published = false
    display.title = keepTitle ? display.title : `${display.title} (Copy)`
    display.updatedAt = new Date().toISOString()
    display.createdAt = new Date().toISOString()

    return this.create(display)
  }
}

export default Display
