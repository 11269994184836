import axios from 'axios'
import { getUserLocale } from 'get-user-locale'
import i18n from 'i18next'
import Cookies from 'js-cookie'
import React, { useCallback } from 'react'

import contentsDefault from '../components/display-content/contentsDefault'
import dayjs from './../lib/dayjs'
import { getDefaultFont } from './defaultFonts'
import timezones from './timezones.json'

const typesOfGame = ['spin-to-win']
let DOMPurify

export const getURL = () => {
  const url =
    process?.env?.URL && process.env.URL !== ''
      ? process.env.URL
      : process?.env?.NEXT_PUBLIC_LIVE_URL &&
        process.env.NEXT_PUBLIC_LIVE_URL !== ''
      ? process.env.NEXT_PUBLIC_LIVE_URL
      : 'http://localhost:3000'
  return url.includes('http') ? url : `https://${url}`
}

export const postData = async ({ url, token, data = {} }) => {
  const res = await fetch(url, {
    method: 'POST',
    headers: new Headers({ 'Content-Type': 'application/json', token }),
    credentials: 'same-origin',
    body: JSON.stringify(data)
  })

  if (res.error) {
    throw res.error
  }

  return res.json()
}

export const toDateTime = (secs) => {
  var t = new Date('1970-01-01T00:30:00Z') // Unix epoch start.
  t.setSeconds(secs)
  return t
}

export const didSubscriptionChange = (newSubscriptionId, subscription) => {
  const current = getCurrentSubscription(subscription)
  return current.id === newSubscriptionId
}

export const getCurrentSubscription = (subscriptionHistory) => {
  if (Array.isArray(subscriptionHistory)) {
    return subscriptionHistory.slice(-1).pop()
  }
  return {}
}

export const stringToBoolean = (value) => {
  if (typeof value === 'string') {
    if (value.toLowerCase() === 'false') {
      return false
    } else if (value.toLowerCase() === 'true') {
      return true
    } else {
      return value
    }
  } else {
    return value
  }
}

export const unitCharToWord = (char, singular) => {
  const map = {
    s: 'seconds',
    m: 'minutes',
    h: 'hours',
    d: 'days'
  }
  return singular ? map[char].slice(0, -1) : map[char]
}

export const validUrl = (url) => {
  try {
    new URL(url)
    return true
  } catch (e) {
    console.log(e.message)
    return false
  }
}
/*
 * @param emails: should be comma separated
 */
export const validEmail = (emails) => {
  return emails.split(',').every((email) => {
    return !!email
      .trim()
      .match(/^[^\s()<>:;,@\\"]+@[\w\d][\w\d\-]+(\.[\w\d\-]+){0,3}$/)
  })
}

export const isGame = (displayType) => {
  return typesOfGame.includes(displayType)
}

export const getCouponOnThankYou = (display) => {
  const couponContents = display.thankYouContents.find(
    (content) => content.type === 'coupon'
  )

  if (
    !couponContents ||
    (couponContents.hidden && !couponContents.sendCouponByMail)
  )
    return null

  return couponContents?.coupon
}

export const positionReverser = (data) => {
  if (detectLang() === 'ja') return [data[1], data[0]]
  else return data
}

export const getCouponOnMain = (display) => {
  const couponContents = display.contents.find(
    (content) => content.type === 'coupon'
  )
  if (!couponContents) return null

  return couponContents.staticCoupon
}

export const detectLang = () => {
  const acceptedLang = ['ja', 'en']
  const localStorageLang =
    typeof localStorage !== 'undefined' && localStorage.getItem('i18nextLng')
  const currentLang = acceptedLang.includes(localStorageLang)
    ? localStorageLang
    : false

  if (currentLang) return currentLang
  else {
    const userLocale = getUserLocale()
    return userLocale.substr(0, 2).toLowerCase() === 'ja' ? 'ja' : 'en'
  }
}

// log specific events happening.
export const gtagEvent = ({ action, params, isStaffAccount }) => {
  if (!isStaffAccount) gtag('event', action, params)
}

export const msClarityEvent = ({ eventType, value, key }) => {
  if (window?.clarity) {
    switch (eventType) {
      case 'identify':
      case 'event':
        window.clarity(eventType, value)
        break

      case 'set':
        window.clarity(eventType, key, value)
        break
    }
  }
}

export const posthogEvent = ({ eventType, key, value }) => {
  if (window?.posthog) {
    switch (eventType) {
      case 'identify':
        window.posthog.identify(key, value)
        break
      case 'capture':
        window.posthog.capture(key, value)
        break
      case 'reset':
        window.posthog.reset()
        break
    }
  }
}

export const isDisplayView = (pathname) => {
  return pathname.match(/^\/displays\/(?!(create|starters))/)
}

export const isEditor = (pathname) => {
  return (
    pathname.match(/^\/ab-test\/.*\/settings/) ||
    pathname.match(/^\/displays\/(?!(create|starters))/)
  )
}

export const shouldHideHeader = (pathname) => {
  return pathname.match(/pricing$/) || isDisplayView(pathname)
}

export const toISODate = (dateString = '') => {
  if (!dateString) return new Date().toISOString()
  return new Date(dateString).toISOString()
}

export const loadOtherLibrary = () => {
  // Gtag script
  window.dataLayer = window.dataLayer || []
  window.gtag = function () {
    dataLayer.push(arguments)
  }
  gtag('js', new Date())
  gtag('config', 'G-JZCXJ6JWLR', {
    page_path: window.location.pathname
  })

  //twitter
  !(function (e, t, n, s, u, a) {
    e.twq ||
      ((s = e.twq =
        function () {
          s.exe ? s.exe.apply(s, arguments) : s.queue.push(arguments)
        }),
      (s.version = '1.1'),
      (s.queue = []),
      (u = t.createElement(n)),
      (u.async = !0),
      (u.src = 'https://static.ads-twitter.com/uwt.js'),
      (a = t.getElementsByTagName(n)[0]),
      a.parentNode.insertBefore(u, a))
  })(window, document, 'script')
  twq('config', 'o7of1')
}

const fontLabelMap = {
  regular: 'Regular',
  italic: 'Italic',
  100: 'Thin 100',
  '100italic': 'Thin 100 italic',
  200: 'Extra-light 200',
  '200italic': 'Extra-light 200 italic',
  300: 'Light 300',
  '300italic': 'Light 300 italic',
  400: 'Regular 400',
  '400italic': 'Regular 400 italic',
  500: 'Medium 500',
  '500italic': 'Medium 500 italic',
  600: 'Semi-bold 600',
  '600italic': 'Semi-bold 600 italic',
  700: 'Bold 700',
  '700italic': 'Bold 700 italic',
  800: 'Semi-black 800',
  '800italic': 'Semi-black 800 italic',
  900: 'Black 900',
  '900italic': 'Black 900 italic'
}

export const formatFontWeightLabel = (key) => {
  return fontLabelMap[key]
}

export const helpPageUrl = (page, category, top = false) => {
  const lang = detectLang() === 'ja' ? 'ja' : 'en'
  if (top) {
    return `https://promolayer.crisp.help/${lang}`
  }
  const dir = category ? 'category' : 'article'
  page = page.replace(/^\//, '')

  return `https://promolayer.crisp.help/${lang}/${dir}/${page}`
}

export const validTarget = (target) => {
  const { matchType, matchText } = target
  if (!matchText) return true
  return !(
    matchType !== 'contains' &&
    (matchText.includes('?') || matchText.includes('#'))
  )
}
export const handleRules = (subject, e, category, subCategory) => {
  switch (category) {
    case 'showOnPages':
    case 'dontShowOnPages':
      if (subCategory === 'add') {
        if (!Array.isArray(subject[category])) subject[category] = []
        subject[category].push({ matchType: 'contains', matchText: '' })
      } else if (subCategory === 'remove') {
        const index = e.currentTarget.dataset.index
        subject[category].splice(index, 1)
      } else if (subCategory === 'deleteAll') {
        subject[category] = []
      } else {
        const sopindex = +e.currentTarget.dataset.index
        const sopname = e.currentTarget.name

        const separator = new RegExp(/[\n|\t|\s]/)
        if (sopname === 'matchText' && e.currentTarget.value.match(separator)) {
          const matchType = subject[category][sopindex]?.matchType ?? 'contains'
          e.currentTarget.value.split(separator).forEach((url, index) => {
            const slug = url.trim()
            if (slug) {
              subject[category][sopindex + index] = {
                matchType,
                matchText: slug
              }
            }
          })
        } else {
          if (!subject[category][sopindex]) {
            subject[category][sopindex] = {
              matchType: 'contains',
              matchText: ''
            }
          }

          subject[category][sopindex][sopname] = e.currentTarget.value
        }

        if (!validTarget(subject[category][sopindex])) {
          subject.errorMessage =
            'URL Equals, Starts with and Ends with match the URL without the search parameters or hash.'
        }
      }
      break
    case 'whenToShow':
      if (subCategory === 'perpetual') {
        //enabled = !e
        subject[category][subCategory] = !e
      } else if (
        subCategory === 'timezone' ||
        subCategory === 'offsetToCurrentTimezone'
      ) {
        subject[category][subCategory] = e.target.value
      } else {
        subject[category][subCategory] = dayjs(e.target.value).toISOString()
      }
      break

    case 'whatTimeFrameToShow':
      if (!subject.whatTimeFrameToShow) {
        subject.whatTimeFrameToShow = {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
      }

      if (subCategory === 'timeFrame') {
        const index = e.target.index
        const type = e.target.type
        if (!subject.whatTimeFrameToShow.timeFrame) {
          subject.whatTimeFrameToShow.timeFrame = []
        }
        if (!subject.whatTimeFrameToShow.timeFrame[index]) {
          subject.whatTimeFrameToShow.timeFrame[index] = {}
        }
        if (type === 'create') {
          subject.whatTimeFrameToShow.timeFrame[index + 1] = {
            start: '00:00',
            end: '23:59',
            dayOfWeek: [true, true, true, true, true, true, true]
          }
        } else if (type === 'delete') {
          subject.whatTimeFrameToShow.timeFrame.splice(index, 1)
        } else {
          if (type === 'dayOfWeek') {
            const dow = e.target.dow
            subject.whatTimeFrameToShow.timeFrame[index][type][dow] =
              e.target.checked
          } else {
            const formatted = dayjs(e.target.value).format('HH:mm')
            let oldTime =
              subject.whatTimeFrameToShow.timeFrame[index][type] ?? '00:00'
            subject.whatTimeFrameToShow.timeFrame[index][type] = formatted

            //Validate
            if (
              subject.whatTimeFrameToShow.timeFrame[index].start >
              subject.whatTimeFrameToShow.timeFrame[index].end
            ) {
              subject.errorMessage = i18n.t(
                'Start time must be earlier than end time'
              )
              subject.whatTimeFrameToShow.timeFrame[index][type] = oldTime
              return subject
            }
          }
        }
      } else if (subCategory === 'timezone') {
        subject.whatTimeFrameToShow.timezone = e.target.value
      }
      break

    case 'showToUsersWho':
      if (e.target?.switch) {
        subject[category][`use${toCapital(subCategory)}`] = e.target.value
      } else if (
        [
          'cartProductFilterDelete',
          'cartProductFilterAdd',
          'cartProductFilterBlur'
        ].includes(subCategory)
      ) {
        if (!Array.isArray(subject.showToUsersWho.cartProductFilter))
          subject.showToUsersWho.cartProductFilter = []

        if (subCategory === 'cartProductFilterAdd') {
          subject.showToUsersWho.cartProductFilter.push(e)
        } else if (subCategory === 'cartProductFilterDelete') {
          subject.showToUsersWho.cartProductFilter.splice(e, 1)
        } else if (subCategory === 'cartProductFilterBlur') {
          if (
            e.length > 0 &&
            !subject.showToUsersWho.cartProductFilter
              .map((item) => item.id)
              .includes(e)
          ) {
            subject.showToUsersWho.cartProductFilter.push({ id: e, label: e })
          }
        }
      } else if (
        subCategory === 'browsingHistory' ||
        subCategory === 'hasNotSeenX'
      ) {
        if (
          !Array.isArray(subject.showToUsersWho[subCategory]) ||
          subject.showToUsersWho[subCategory].length === 0
        ) {
          subject.showToUsersWho[subCategory] = [
            {
              numberOfTimes: 'any',
              target: { matchType: 'equals', matchText: '' }
            }
          ]
        }
        const buttonIndex = e.target.index
        if (e.target.type === 'create') {
          subject.showToUsersWho[subCategory].push({
            numberOfTimes: 'any',
            target: { matchType: 'equals', matchText: '' }
          })
        } else if (e.target.type === 'delete') {
          subject.showToUsersWho[subCategory].splice(buttonIndex, 1)
        } else {
          const contentIndex = +e.target.dataset.index
          const { name, value } = e.target
          if (name === 'numberOfTimes') {
            subject.showToUsersWho[subCategory][contentIndex].numberOfTimes =
              value
          } else if (name === 'matchText') {
            subject.showToUsersWho[subCategory][contentIndex].target[
              e.target.name
            ] = value
          } else {
            subject.showToUsersWho[subCategory][contentIndex].target[
              e.target.name
            ] = value
          }
        }
      } else if (
        subCategory === 'cameFrom' ||
        subCategory === 'osList' ||
        subCategory === 'cartProductFilter' ||
        subCategory === 'userTags'
      ) {
        if (Array.isArray(e)) {
          e = e.map((item) => ({ value: item.value, label: item.label }))
        }
        subject.showToUsersWho[subCategory] = e
      } else if (subCategory === 'promolayerCampaigns') {
        const buttonIndex = e.target.index
        if (e.target.type === 'create') {
          subject.showToUsersWho[subCategory].push({ displayId: '' })
        } else if (e.target.type === 'delete') {
          subject.showToUsersWho[subCategory].splice(buttonIndex, 1)
        } else {
          const contentIndex = +e.target.dataset.index
          const { name, value } = e.target
          if (name === 'hasSeen') {
            subject.showToUsersWho[subCategory][contentIndex].hasSeen = value
          } else {
            subject.showToUsersWho[subCategory][contentIndex].displayId = value
          }
        }
      } else if (subCategory === 'clickHistory') {
        const buttonIndex = e.target.index
        if (e.target.type === 'create') {
          subject.showToUsersWho[subCategory].push({
            displayId: 'current-display'
          })
        } else if (e.target.type === 'delete') {
          subject.showToUsersWho[subCategory].splice(buttonIndex, 1)
        } else {
          const contentIndex = +e.target.dataset.index
          const { name, value } = e.target
          subject.showToUsersWho[subCategory][contentIndex][name] = value
        }
      } else if (subCategory === 'blockIP') {
        const buttonIndex = e.target.index
        if (e.target.type === 'create') {
          subject.showToUsersWho[subCategory].push({ ipAddress: '' })
        } else if (e.target.type === 'delete') {
          subject.showToUsersWho[subCategory].splice(buttonIndex, 1)
        } else {
          const contentIndex = +e.target.dataset.index
          const { name, value } = e.target
          if (name === 'ipAddress') {
            subject.showToUsersWho[subCategory][contentIndex].ipAddress = value
          }
        }
      } else {
        subject[category][subCategory] = e.target.value
        if (subCategory === 'browserLanguage' && e.target.value === 'any')
          subject.showToUsersWho.browserLanguageEquals = 'equals'
      }

      break

    case 'triggersWhen':
      if (
        [
          'onBrowserBack',
          'onExitIntent',
          'useTimeOnSiteLongerThan',
          'useTimeOnSiteLongerThan',
          'useTimeWithoutOperation',
          'useScrollDepthReaches',
          'enabledAddToCart',
          'useScrollBack',
          'useComeBack',
          'immediate'
        ].includes(subCategory)
      ) {
        subject[category][subCategory] = !subject[category][subCategory]
      } else {
        subject[category][subCategory] = e.target?.value
      }

      break

    case 'shouldTheDisplayRecur':
      if (subCategory === 'showAgainIfConverts')
        subject[category][subCategory] = !subject[category][subCategory]
      else subject[category][subCategory] = e.target.value
      break

    default:
      subject[category] = e.target.value
  }

  return subject
}
export const getIcon = (type, abTest, iconSize) => {
  iconSize = iconSize || '32px'
  return (
    <span>
      {type === 'popup' && (
        <img
          style={{ width: '100%', maxWidth: iconSize }}
          src="/assets/images/icons/popup-highlighted.svg"
        />
      )}
      {type === 'full-screen' && (
        <img
          style={{ width: '100%', maxWidth: iconSize }}
          className="dashicon"
          src="/assets/images/icons/fullscreen-highlighted.svg"
        />
      )}
      {type === 'slide-in' && (
        <img
          style={{ width: '100%', maxWidth: iconSize }}
          className="dashicon"
          src="/assets/images/icons/slidein-highlighted.svg"
        />
      )}
      {type === 'header-footer' && (
        <img
          style={{ width: '100%', maxWidth: iconSize }}
          className="dashicon"
          src="/assets/images/icons/headerfooter-highlighted.svg"
        />
      )}
      {type === 'spin-to-win' && (
        <img
          style={{ width: '100%', maxWidth: iconSize }}
          className="dashicon"
          src="/assets/images/icons/spintowin-highlighted.svg"
        />
      )}
      {type === 'present' && (
        <img
          style={{ width: '100%', maxWidth: iconSize }}
          className="dashicon"
          src="/assets/images/icons/present-highlighted.svg"
        />
      )}
      {type === 'image-only' && (
        <img
          style={{ width: '100%', maxWidth: iconSize }}
          className="dashicon"
          src="/assets/images/icons/single-image-highlighted.svg"
        />
      )}
      {abTest && (
        <i
          className="mdi mdi-flask"
          style={{ color: '#5C3DC2', fontSize: iconSize }}
        />
      )}
    </span>
  )
}

export const getTypeLabel = (type) => {
  const typeMap = {
    popup: 'Popup',
    'slide-in': 'Slide in',
    'header-footer': 'Header/Footer',
    inline: 'Inline',
    'full-screen': 'Full screen',
    'spin-to-win': 'Spin to win',
    'image-only': 'Single image'
  }
  return typeMap[type]
}

export const getMiscLabel = (value) => {
  const labelMap = {
    equals: 'URL Equals',
    contains: 'URL Contains',
    startsWith: 'URL Starts with',
    'element-exists': 'Element exists',
    'element-has-class': 'Element has CSS class',
    'element-has-property': 'Element has CSS property',
    'element-has-attribute': 'Element has attribute',
    'has-js-variable': 'Has global JS variable',
    'has-cookie': 'Has cookie',
    'url-regex': 'URL regex match',
    'page-title': 'Page title contains',
    endsWith: 'URL Ends with',
    'has-seen': 'Has seen',
    'has-not-seen': 'Has not seen',
    subscribers: 'Subscribers',
    'non-subscribers': 'Non-subscribers',
    'new-only': 'New visitors only',
    'return-only': 'Returning visitors only',
    facebook: 'Facebook',
    instagram: 'Instagram',
    youtube: 'Youtube',
    twitter: 'Twitter',
    tiktok: 'TikTok',
    reddit: 'Reddit',
    pinterest: 'Pinterest',
    'any-search-engine': 'Any search engine',
    'any-social-network': "Any social network'",
    'in-app-browser': 'In app browser',
    'line-app-browser': 'Line app browser',
    line: 'Line',
    'yappli-app-browser': 'Yappli app browser',
    'no-referrer': 'No referrer (Referrer is not set)',
    'has-referrer': 'Has referrer (Any referrer is set)',
    does_not_equals: 'Does not equal',
    total: 'Ever',
    session: 'In single session'
  }
  return labelMap[value] ?? value
}

export const getURLOfFileInFirebaseStorage = (filePath, version = 0) => {
  return `https://firebasestorage.googleapis.com/v0/b/promolayer-app.appspot.com/o/${encodeURIComponent(
    filePath
  )}?alt=media&v=${version}`
}

export const noImageUrl = () => {
  return 'https://firebasestorage.googleapis.com/v0/b/promolayer-app.appspot.com/o/uploaded%2FEEazi8xNIHbn3fWUx3I49jvOGBZ2%2F_1ff4n9c6s0p7s59l9cu?alt=media&token=c8b34fe5-977c-45af-9132-8af6e07a14be'
}

export const getThumbnail = (display) => {
  if (display.abTest) {
    return display.thumbnailSrc
      ? getURLOfFileInFirebaseStorage(display.thumbnailSrc)
      : noImageUrl()
  } else {
    return getURLOfFileInFirebaseStorage(display.thumbnailSrc)
  }
}

export const lightOrDark = (color) => {
  if (!color) return 'light'
  const { r = 0, g = 0, b = 0 } = color

  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b))

  if (hsp > 127.5) {
    return 'light'
  } else {
    return 'dark'
  }
}

export const toCapital = (str) => {
  if (!str) return str
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const sendCurrentMode = (mode, id, iframeId) => {
  const childFrameObj = document.getElementById(iframeId)
  mode = mode.replace('Mode', '').toLowerCase()
  if (childFrameObj) {
    childFrameObj.contentWindow?.postMessage({ mode, id, plPreview: true }, '*')
  }

  //Hide Crisp
  const crispEl = document.querySelector('.crisp-client')
  if (crispEl) {
    //Cant use toggle
    if (['teaser', 'couponbar'].includes(mode)) crispEl.classList.add('d-none')
    else crispEl.classList.remove('d-none')
  }
}

export const shouldCheckCouponPool = (display) => {
  if (display.type === 'spin-to-win') {
    const usedSegments =
      display.wheelSegments?.slice(0, +display.wheelSegmentCount) ?? []
    return usedSegments.some(
      (segment) => segment.coupon?.couponType === 'pool' && segment.coupon.pool
    )
  } else if (display.showForm) {
    const couponContents = display.thankYouContents.find(
      (content) => content.type === 'coupon'
    )
    return (
      couponContents &&
      couponContents.coupon?.couponType === 'pool' &&
      (!couponContents.hidden || couponContents.sendCouponByMail)
    )
  } else {
    return false
  }
}

export const couponPoolRemains = async (display) => {
  try {
    const res = await fetch(
      `${process.env.NEXT_PUBLIC_API_HOST}/coupon-pool-check/${display.id}`
    )
    if (res.ok) {
      const json = await res.json()
      return json.result
    } else {
      return false
    }
  } catch (e) {
    console.log(e)
    return false
  }
}

export const availableInSchedule = (display) => {
  if (
    !display.useSchedule ||
    !Array.isArray(display.whatTimeFrameToShow?.timeFrame) ||
    display.whatTimeFrameToShow.timeFrame.length === 0
  ) {
    return true
  }
  const timeFrames = []
  display.whatTimeFrameToShow?.timeFrame.forEach((item) => {
    const newItem = {}
    newItem.start = item.start
    newItem.end = item.end
    newItem.dayOfWeek = item.dayOfWeek
    if (newItem.start !== newItem.end) {
      timeFrames.push(newItem)
    }
  })

  if (timeFrames.length === 0) {
    return true
  }

  const currentTime = new Date().toLocaleString('sv-SE', {
    timeZone: display.whatTimeFrameToShow.timezone
  })
  const hmOnly = currentTime.split(' ')[1].slice(0, -3)
  const theDay = new Date(currentTime).getDay()
  return timeFrames.some(
    (tf) => tf.start <= hmOnly && tf.end >= hmOnly && tf.dayOfWeek[theDay]
  )
}
export const sendToLogDNA = (message) => {
  const url = `${process.env.NEXT_PUBLIC_API_HOST}/logdna`
  axios.post(url, { message }).then((data) => {
    console.log(data)
  })
}

export const toReadableNumber = (number = 0, denominator = false) => {
  let unit = ''
  if (number >= 1000 && number < 1000000) {
    number = number / 1000
    unit = 'K'
  } else if (number >= 1000000 && number < 1000000000) {
    number = number / 1000000
    unit = 'M'
  } else if (number >= 1000000000) {
    number = number / 1000000000
    unit = 'B'
  }

  if (denominator) {
    number = number.toFixed?.(0)
  } else if (unit) {
    number = number.toFixed?.(1)
  }
  return number + unit
}

export const createCrispChat = (userData) => {
  window.$crisp = []
  window.$crisp.push(['safe', true])
  window.$crisp.push(['set', 'user:email', [userData.email]])
  window.CRISP_TOKEN_ID = userData.userId
  window.CRISP_WEBSITE_ID = '93a1ab7c-8ccf-4c44-9f29-61997547af0b'
  window.CRISP_RUNTIME_CONFIG = {
    locale: detectLang()
  }
  ;(function () {
    let d = document
    let s = d.createElement('script')
    s.src = '//client.crisp.chat/l.js'
    s.async = 1
    d.getElementsByTagName('head')[0].appendChild(s)
  })()
}

export const valueToLabel = (value) => {
  if (!isNaN(value)) return value

  let defaultValue = ''
  value = value || defaultValue
  const t = i18n.t.bind(i18n)
  switch (value) {
    case 'oversize1':
      return t('Oversize 1')
    case 'oversize2':
      return t('Oversize 2')
    case 'h1':
    case 'h2':
    case 'h3':
    case 'h4':
    case 'h5':
    case 'left':
    case 'right':
    case 'center':
      return t(toCapital(value))
    case 'auto':
      return t('Center')
    case 'start':
      return t('Left')
    case 'end':
      return t('Right')
    case 'inline':
      return t('Size with content')
    case 'block':
      return t('Stretch full width')

    default:
      return ''
  }
}

export const toRGBA = (data, defaultColor = 'grey') => {
  if (!data && defaultColor) return defaultColor

  if (typeof data.r === 'undefined') return false

  return `rgb(${data.r},${data.g},${data.b},${data.a})`
}

export const toRGBAObject = (data) => {
  const matched = data.match(
    /rgba?\((\d{1,3}),\s?(\d{1,3}),\s?(\d{1,3}),?\s?([0-9.]{1,4})?/i
  )

  if (matched) {
    return { r: matched[1], g: matched[2], b: matched[3], a: matched[4] ?? 1 }
  } else if (data.startsWith('#')) {
    return data
  }
  return { r: 0, g: 0, b: 0, a: 1 }
}

export const setDefault = (display, abTest) => {
  if (
    display.showToUsersWho.useBrowserLanguage === undefined &&
    display.showToUsersWho.browserLanguage &&
    display.showToUsersWho.browserLanguage !== 'any'
  ) {
    display.showToUsersWho.useBrowserLanguage = true
  }

  if (
    display.showToUsersWho.useCameFrom === undefined &&
    !Array.isArray(display.showToUsersWho.cameFrom) &&
    display.showToUsersWho.cameFrom !== 'any'
  ) {
    display.showToUsersWho.useCameFrom = true
  }

  if (
    display.showToUsersWho.useDeviceType === undefined &&
    display.showToUsersWho.deviceType &&
    display.showToUsersWho.deviceType !== 'any'
  ) {
    display.showToUsersWho.useDeviceType = true
  }

  if (
    display.showToUsersWho.useFrequency === undefined &&
    display.showToUsersWho.frequency &&
    display.showToUsersWho.frequency !== 'any'
  ) {
    display.showToUsersWho.useFrequency = true
  }

  if (
    display.showToUsersWho.useLoginState === undefined &&
    display.showToUsersWho.loginState &&
    display.showToUsersWho.loginState !== 'any'
  ) {
    display.showToUsersWho.useLoginState = true
  }

  if (
    display.showToUsersWho.useCartState === undefined &&
    display.showToUsersWho.cartState &&
    display.showToUsersWho.cartState !== 'any'
  ) {
    display.showToUsersWho.useCartState = true
  }

  if (!display.showToUsersWho.hasNotSeenX?.length) {
    display.showToUsersWho.hasNotSeenX = [
      {
        numberOfTimes: 'any',
        target: { matchType: 'equals', matchText: '' }
      }
    ]
  }

  if (!display.showToUsersWho.browsingHistory?.length) {
    display.showToUsersWho.browsingHistory = [
      {
        numberOfTimes: 'any',
        target: { matchType: 'equals', matchText: '' }
      }
    ]
  }

  if (!display.showToUsersWho.blockIP?.length) {
    display.showToUsersWho.blockIP = [{ ipAddress: '' }]
  }

  if (!display.showToUsersWho.promolayerCampaigns?.length) {
    display.showToUsersWho.promolayerCampaigns = [{ displayId: '' }]
  }

  if (!display.showToUsersWho.clickHistory?.length) {
    display.showToUsersWho.clickHistory = [{ displayId: 'current-display' }]
  }

  if (display.style) {
    if (!display.closeButtonBgColor)
      display.closeButtonBgColor =
        display.style === 'light'
          ? { r: 255, g: 255, b: 255, a: 1 }
          : { r: 0, g: 0, b: 0, a: 0.8 }

    if (!display.closeButtonIconColor)
      display.closeButtonIconColor =
        display.style === 'light'
          ? { r: 0, g: 0, b: 0, a: 0.8 }
          : { r: 255, g: 255, b: 255, a: 1 }

    display.style = false
  }

  if (abTest) {
    display.isABTest = true
  }

  return display
}

const htmlValidator = async (html) => {
  if (!DOMPurify) {
    const createDOMPurify = (await import('dompurify')).default
    DOMPurify = createDOMPurify(window)
  }
  return DOMPurify.sanitize(html, {
    FORCE_BODY: true,
    ADD_TAGS: ['iframe', 'style', 'script'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling']
  })
}

const validateHTML = async (display) => {
  for (let content of display.contents) {
    if (content.type === 'html') {
      content.html = await htmlValidator(content.html)
    }
  }

  for (let content of display.thankYouContents) {
    if (content.type === 'html') {
      content.html = await htmlValidator(content.html)
    }
  }

  return display
}

const validateURL = (display) => {
  if (Array.isArray(display.showOnPages)) {
    display.showOnPages.forEach((page) => {
      if (['equals', 'startsWith', 'endsWith'].includes(page.matchType)) {
        try {
          if (page.matchType === 'equals') new URL(page.matchText)
          page.matchText = page.matchText.replace(/\?.*$/, '')
        } catch (e) {
          page.matchText = i18n.t('Invalid URL')
        }
      }
    })
  }

  if (Array.isArray(display.dontShowOnPages)) {
    display.dontShowOnPages.forEach((page) => {
      if (['equals', 'startsWith', 'endsWith'].includes(page.matchType)) {
        try {
          if (page.matchType === 'equals') new URL(page.matchText)
          page.matchText = page.matchText.replace(/\?.*$/, '')
        } catch (e) {
          page.matchText = i18n.t('Invalid URL')
        }
      }
    })
  }

  return display
}

export const filterEmptyRules = (display) => {
  if (Array.isArray(display.showOnPages)) {
    display.showOnPages = display.showOnPages.filter(
      (item) =>
        item.matchText.trim() ||
        item.matchElement?.trim() ||
        item.matchValue?.trim()
    )
  }

  if (Array.isArray(display.dontShowOnPages)) {
    display.dontShowOnPages = display.dontShowOnPages.filter(
      (item) =>
        item.matchText.trim() ||
        item.matchElement?.trim() ||
        item.matchValue?.trim()
    )
  }

  if (Array.isArray(display.showOnPagesV2?.targetRules)) {
    display.showOnPagesV2.targetRules.forEach((group) => {
      if (!group.rules) return
      group.rules = group.rules.filter(
        (item) =>
          item.matchText.trim() ||
          item.matchElement?.trim() ||
          item.matchValue?.trim()
      )
    })
    display.showOnPagesV2.targetRules =
      display.showOnPagesV2.targetRules.filter(
        (group) => group.rules?.length > 0
      )
  }

  if (Array.isArray(display.showOnPagesV2?.excludeRules)) {
    display.showOnPagesV2.excludeRules.forEach((group) => {
      if (!group.rules) return
      group.rules = group.rules.filter(
        (item) =>
          item.matchText.trim() ||
          item.matchElement?.trim() ||
          item.matchValue?.trim()
      )
    })
    display.showOnPagesV2.excludeRules =
      display.showOnPagesV2.excludeRules.filter(
        (group) => group.rules?.length > 0
      )
  }
  if (Array.isArray(display.showToUsersWho.browsingHistory)) {
    display.showToUsersWho.browsingHistory =
      display.showToUsersWho.browsingHistory.filter((item) =>
        item.target.matchText.trim()
      )
    if (display.showToUsersWho.browsingHistory.length === 0) {
      display.showToUsersWho.browsingHistory = [
        {
          numberOfTimes: 'any',
          target: { matchType: 'equals', matchText: '' }
        }
      ]
    }
  }

  if (Array.isArray(display.showToUsersWho.hasNotSeenX)) {
    display.showToUsersWho.hasNotSeenX =
      display.showToUsersWho.hasNotSeenX.filter((item) =>
        item.target.matchText.trim()
      )
    if (display.showToUsersWho.hasNotSeenX.length === 0) {
      display.showToUsersWho.hasNotSeenX = [
        {
          numberOfTimes: 'any',
          target: { matchType: 'equals', matchText: '' }
        }
      ]
    }
  }

  if (Array.isArray(display.showToUsersWho.blockIP)) {
    display.showToUsersWho.blockIP = display.showToUsersWho.blockIP.filter(
      (item) => item.ipAddress.trim()
    )
    if (display.showToUsersWho.blockIP.length === 0) {
      display.showToUsersWho.blockIP = [{ ipAddress: '' }]
    }
  }

  if (Array.isArray(display.showToUsersWho.promolayerCampaigns)) {
    display.showToUsersWho.promolayerCampaigns =
      display.showToUsersWho.promolayerCampaigns.filter((item) =>
        item.displayId.trim()
      )
    if (display.showToUsersWho.promolayerCampaigns.length === 0) {
      display.showToUsersWho.promolayerCampaigns = [
        { displayId: '', hasSeen: '' }
      ]
    }
  }

  if (Array.isArray(display.showToUsersWho.clickHistory)) {
    display.showToUsersWho.clickHistory =
      display.showToUsersWho.clickHistory.filter(
        (item) => item.displayId && item.buttonId && item.hasClicked
      )
    if (display.showToUsersWho.clickHistory.length === 0) {
      display.showToUsersWho.clickHistory = [{ displayId: 'current-display' }]
    }
  }

  return display
}

export const normalizeDisplay = async (display, isPreview) => {
  //Delete isDirty
  delete display.isDirty
  //Delete template props
  delete display.language
  delete display.categories
  delete display.tags
  delete display._tags
  delete display.highlight
  delete display._ordering
  delete display.default
  delete display.defaultMatch
  delete display.perfectMatch

  if (!isPreview) {
    if (!Cookies.get('impersonate-login'))
      display.updatedAt = new Date().toISOString()

    if (display.published) display.publishedBefore = true
  }
  //Filter empty rules
  display = filterEmptyRules(display)
  //validate html contents
  display = await validateHTML(display)
  display = validateURL(display)

  return display
}

export const setDisplayDefaultOnCreate = (
  title,
  display,
  currentWorkspace,
  userData,
  list,
  currentPlan,
  abTest
) => {
  delete display.id //Each template includes its own id
  delete display._ordering
  delete display.thumbnail
  delete display.tags
  delete display.font
  delete display.highlight
  delete display.categories

  display.title = title
  display.createdAt = dayjs().toISOString()
  display.updatedAt = dayjs().toISOString()
  display.triggersWhen.timeOnSiteLongerThan ||= 10
  display.triggersWhen.timeWithoutOperation ||= 10
  display.triggersWhen.scrollDepthReaches ||= 20
  display.whenToShow.perpetual = true
  display.whenToShow.start = dayjs().toISOString()
  display.whenToShow.end = dayjs().add(30, 'days').toISOString()
  display.enterEmailInto = { promolayerList: list[0].id, mailchimpList: '' }
  display.followUpEmail = [{ email: '', timing: 'immediately' }]
  display.notification = { active: false, email: '' }
  display.published = !!abTest
  display.publishedBefore = !!abTest
  display.workspace = currentWorkspace.id
  display.useNewEditor = true
  display.showPromolayerLogo = currentPlan === 'Free'
  display.forms = setDefaultTranslation(display.forms)
  display.tabPosition ||= 'bottom-right'
  display.tabPositionMobile ||= 'bottom-right'
  display.tabFont ||= getDefaultFont('tab')

  const countdown = display.contents?.find(
    (content) => content.type === 'countdown'
  )

  if (countdown)
    countdown.endDatetime = parseInt(Date.now() / 1000 + 60 * 60 * 48)

  const SAMPLE_COUPON_CODE = 'SAMPLEONLY'

  const mainCoupon = display.contents?.find(
    (content) => content.type === 'coupon'
  )

  if (mainCoupon) mainCoupon.staticCoupon = SAMPLE_COUPON_CODE

  const thankYouCoupon = display.thankYouContents?.find(
    (content) => content.type === 'coupon'
  )
  if (thankYouCoupon && typeof thankYouCoupon.coupon === 'object')
    thankYouCoupon.coupon.couponCode = SAMPLE_COUPON_CODE

  if (display.type === 'spin-to-win') {
    display.wheelSegments.forEach((seg) => {
      seg.coupon.couponCode = SAMPLE_COUPON_CODE
    })
  }

  if (currentWorkspace.invited) {
    display.userId = currentWorkspace.userId
    display.createdBy = userData.userId
  } else {
    display.userId = userData.userId
  }

  if (abTest) {
    display.abTest = abTest
  }

  return display
}

export const showTimezoneOffset = (offset) => {
  const d = dayjs('1970-01-01T00:00:00Z').utc()
  if (offset >= 0) {
    return `+${d.add(offset, 'hours').format('HH:mm')}`
  } else {
    return `-${d.subtract(offset, 'hours').format('HH:mm')}`
  }
}

export const getUserTimezone = (timezone) => {
  let tz
  if (timezone) {
    tz = timezones.find((tz) => tz.en === timezone)
  } else {
    const timeZoneOffset = Math.round(-new Date().getTimezoneOffset() / 60)
    tz = timezones.find((tz) => tz.offset === timeZoneOffset) ?? timezones[0] //UTC
  }

  return tz
}

const setDefaultTranslation = (forms) => {
  forms.forEach((item) => {
    if (item.id === 'name') {
      item.label = i18n.t('Name')
      item.furiganaLabel = i18n.t('Furigana')
      item.placeholder = i18n.t('Name')
      item.furiganaPlaceholder = i18n.t('Furigana')

      item.firstLabel = i18n.t('First name')
      item.lastLabel = i18n.t('Last name')
      item.firstPlaceholder = i18n.t('First name')
      item.lastPlaceholder = i18n.t('Last name')

      item.firstFuriganaLabel = i18n.t('First furigana')
      item.lastFuriganaLabel = i18n.t('Last furigana')

      item.firstFuriganaPlaceholder = i18n.t('First furigana')
      item.lastFuriganaPlaceholder = i18n.t('Last furigana')
    } else if (item.id === 'email') {
      item.label = i18n.t('Email')
      item.placeholder = i18n.t('Email')
    } else if (item.id === 'phoneNumber') {
      item.label = i18n.t('Phone number')
      item.placeholder = i18n.t('Phone number')
    }
  })

  return forms
}

// a little function to help us with reordering the result
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
}

const grid = '8'

export const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: 0,
  display: 'flex',
  margin: `0 0 ${grid}px 0`,
  fontSize: '14px',
  // change background colour if dragging
  background: isDragging ? 'grey' : 'white',
  borderRadius: '5px',
  boxShadow: '5px 5px 15px rgba(0,0,0,0.05)',
  color: '#74788d',
  // styles we need to apply on draggables
  ...draggableStyle
})

export const getListStyle = (isDraggingOver, displayType) => ({
  padding: grid,
  width: '100%',
  display: displayType === 'image-only' ? 'none' : 'block'
})

export const isIframe = () => {
  return window.location !== window.parent.location
}

export const openEditor = async (router, displayId, shopifyEmbeded, abTest) => {
  if (shopifyEmbeded) {
    await router.push({
      pathname: '/shopify/editor-modal',
      query: { path: `/displays/${displayId}`, abTest: abTest }
    })
  } else {
    await router.push(`/displays/${displayId}`)
  }
}

export const getPlanViews = (plan) => {
  const planViews = {
    free: 1000,
    micro: 5000,
    basic: 15000,
    standard: 100000,
    professional: 300000,
    enterprise: 1000000,
    'plus level 1': 3000000,
    'plus level 2': 10000000,
    'plus level 3': 10000000,
    'plus level 4': 10000000,
    unlimited: 1000000000
  }

  return planViews[plan.toLowerCase()]
}

export const getPlanWorkspace = (plan) => {
  const planWorkspace = {
    free: 1,
    micro: 1,
    basic: 1,
    standard: 1,
    professional: 2,
    enterprise: 5,
    'plus level 1': 15,
    'plus level 2': 30,
    'plus level 3': 60,
    'plus level 4': 100,
    unlimited: 10000
  }

  return planWorkspace[plan.toLowerCase()]
}

export const getTimeZoneBasedYearAndMonth = (timezone) => {
  return dayjs().tz(timezone).format('YYYY-MM')
}

export const getJSTStartAndEndOfMonth = () => {
  const JSTTimeString = new Date().toLocaleString('sv-SE', {
    timeZone: 'Asia/Tokyo'
  })
  const startOfMonth = dayjs(new Date(JSTTimeString))
    .tz('Asia/Tokyo')
    .startOf('month')
    .unix()
  const endOfMonth = dayjs(new Date(JSTTimeString))
    .tz('Asia/Tokyo')
    .endOf('month')
    .unix()
  return { startOfMonth, endOfMonth }
}

export const tryParse = (json) => {
  try {
    if (!json) return null
    return JSON.parse(json)
  } catch (e) {
    return null
  }
}

export const isVideo = (url) => {
  if (!url) return false
  return url.match(/\.(mp4|webm|ogg)/i)
}
