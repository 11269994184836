const defaultComponents = {
  sticker: {
    transform: 'translate(0%,0%)',
    mobileTransform: 'translate(0%,0%)',
    imageUrl: '',
    mobileWidth: 100,
    desktopWidth: 100,
    link: '',
    linkTarget: '_blank',
    offsetTop: '100',
    mobileOffsetTop: '100',
    offsetLeft: '100',
    mobileOffsetLeft: '100',
    zindex: '5',
    animationClass: '',
    animationSpeed: '',
    animationDelay: 0,
    animationLooping: 'animate__repeat-1',
    visibility: 'all'
  },
  heading: {
    headingTag: 'h3',
    headingTagMobile: 'h3',
    text: '',
    visibility: 'all',
    alignment: 'center',
    alignmentMobile: 'center',
    textColor: { r: 51, g: 51, b: 51, a: 1 },
    fontWeight: '400',
    font: { family: 'Roboto' },
    fixedContents: false
  },
  text: {
    textContent: '',
    textContentHTML: '<div></div>',
    linkTextDecoration: 'underline',
    textColor: { r: 51, g: 51, b: 51, a: 1 },
    linkColor: { r: 0, g: 0, b: 238, a: 1 },
    linkHoverColor: { r: 0, g: 0, b: 238, a: 1 },
    fontWeight: '400',
    font: { family: 'Roboto' },
    visibility: 'all'
  },
  button: {
    textColor: {
      r: 255,
      g: 255,
      b: 255,
      a: 1
    },
    buttonTwoTextColor: {
      r: 255,
      g: 255,
      b: 255,
      a: 1
    },
    buttonColor: {
      r: 92,
      g: 61,
      b: 194,
      a: 1
    },
    buttonTwoColor: {
      r: 92,
      g: 61,
      b: 194,
      a: 1
    },
    text: 'Click me',
    size: 'md',
    buttonType: 'single',
    buttonStyle: 'btn',
    alignSelf: 'auto',
    shape: 'normal',
    linkTarget: '_blank',
    stretch: 'inline',
    buttonAction: 'link',
    stretchMobile: 'inline',
    buttonTwoLinkTarget: '_blank',
    visibility: 'all',
    closeAfterLinkClick: false,
    buttonTwoCloseAfterLinkClick: false
  },
  spacer: {
    size: 12, // Size in pixels
    sizeMobile: 6, // Size in pixels
    horizontal: false, // Bool for using in horizontal layouts like banners
    visibility: 'all'
  },
  divider: {
    width: 100, // Width in percentage
    thickness: 1, // Divider thickness in PX
    color: { r: 224, g: 224, b: 224, a: 100 }, // Color in HEX or RGBA
    style: 'solid', // CSS Style attrib (solid, dotted etc)
    visibility: null,
    horizontal: false // Bool for using in ho
  },
  coupon: {
    textColor: {
      r: 0,
      g: 0,
      b: 0,
      a: 1
    },
    bgColor: {
      r: 255,
      g: 255,
      b: 255,
      a: 1
    },
    borderColor: {
      r: 0,
      g: 0,
      b: 0,
      a: 1
    },
    fontSize: 16,
    stretch: 'inline',
    stretchMobile: 'block',
    staticCoupon: 'COUPONCODE'
  },
  image: {
    imageUrl: '',
    visibility: 'all',
    desktopWidth: 100,
    mobileWidth: 100,
    linkTarget: '_blank',
    link: '',
    alignSelf: 'auto',
    alignSelfMobile: 'auto',
    borderRadius: 0,
    width: 0,
    height: 0
  },
  form: {
    formSettings: {
      spaceBetweenFields: 'compact',
      labelColor: { r: 0, g: 0, b: 0, a: 1 },
      bgColor: { r: 255, g: 255, b: 255, a: 1 },
      borderColor: { r: 206, g: 212, b: 218, a: 1 },
      placeholderColor: { r: 117, g: 117, b: 117, a: 1 },
      inputTextColor: { r: 51, g: 51, b: 51, a: 1 }
    }
  },
  overlay: {
    overlayType: 'solid',
    backgroundColor: { r: 255, g: 255, b: 255, a: 1 },
    overlayColor: { r: 0, g: 0, b: 0, a: 0.3 },
    overlayColorStart: { r: 80, g: 165, b: 241, a: 0.4 },
    overlayColorEnd: { r: 52, g: 195, b: 143, a: 0.4 },
    backgroundDirection: 'bottom',
    scrollElement: null
  }
}

export default defaultComponents
