import { usePlatform } from '../../providers/PlatformProvider'
import ShopifyInstallationModal from './InstallationModals/ShopifyInstallationModal'
import WixInstallationModal from './InstallationModals/WixInstallationModal'

const getComponent = (platform) => {
  const map = {
    shopify: ShopifyInstallationModal,
    wix: WixInstallationModal
  }

  return map[platform] ?? null
}

const InstallationModal = () => {
  const { platform } = usePlatform()

  const ModalComponent = getComponent(platform.type)
  return <ModalComponent />
}

export default InstallationModal
