import Image from 'next/image'
import { useRouter } from 'next/router'
import React from 'react'

const Logo = ({ privateRoute }) => {
  const { push } = useRouter()
  const onClick = (e) => {
    if (privateRoute) {
      e.preventDefault()
      push('/')
    }
  }
  return (
    <div className="navbar-brand-box">
      <a
        href=""
        className="promolayer-logo"
        onClick={onClick}
        suppressHydrationWarning={true}
      >
        <Image
          src="/assets/images/promolayer-logo-white.svg"
          alt="Promolayer"
          width={122}
          height={33}
          placeholder={'blur'}
          blurDataURL={'/'}
          suppressHydrationWarning={true}
        />
      </a>
    </div>
  )
}

export default Logo
