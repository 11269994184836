import { useRouter } from 'next/router'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { setDefault } from '../lib/helpers'
import { useFirebase } from './FirebaseProvider'
import { useToast } from './ModalProvider'
import { useWorkspace } from './WorkspaceProvider'

const DisplayDataContext = createContext()

const DisplayDataProvider = ({ children }) => {
  const [displayData, setDisplayData] = useState(null)
  const [isDirty, setIsDirty] = useState(false)
  const { hasPermissionTo } = useWorkspace()
  const { Display } = useFirebase()
  const { t } = useTranslation()
  const { showToast } = useToast()
  const router = useRouter()

  const fetchDisplayData = async (displayId) => {
    if (displayId) {
      try {
        let display = await Display.get(displayId)
        if (display && hasPermissionTo(display)) {
          display = setDefault(display)

          setDisplayData({ ...display })
          localStorage.setItem('currentDisplayId', displayId)
        } else {
          await router.push('/displays')
        }
      } catch (e) {
        showToast({
          title: t('Error'),
          message: t('Something went wrong with the server'),
          isError: true
        })
      }
    }
  }

  useEffect(() => {
    if (router.pathname.startsWith('/displays/') && router.query.id) {
      fetchDisplayData(router.query.id).catch()
    } else {
      setDisplayData(null)
    }
  }, [router.query.id])

  return (
    <DisplayDataContext.Provider
      value={{ displayData, setDisplayData, isDirty, setIsDirty }}
    >
      {children}
    </DisplayDataContext.Provider>
  )
}

const useDisplayData = () => useContext(DisplayDataContext)
export { useDisplayData, DisplayDataProvider }
