import classnames from 'classnames'
import { object } from 'prop-types'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Toast,
  ToastBody,
  ToastHeader
} from 'reactstrap'

const ModalContext = createContext()

const baseOptions = {
  message: '',
  size: 'md',
  title: '',
  innerComponent: <></>,
  isError: false,
  hideHeader: false,
  hideFooter: true,
  backdrop: true,
  scrollable: false,
  classNames: {},
  customPadding: 0
}

const modalOptions = {}
const ModalProvider = ({ children }) => {
  const [visible, setVisible] = useState(false)
  //const [modalOptions,setModalOptions] = useState({})
  const { t } = useTranslation()
  const toggle = () => setVisible(!visible)

  const hideModal = () => {
    setVisible(false)
  }

  const showModal = (options) => {
    Object.assign(modalOptions, baseOptions, options)
    setVisible(true)
  }

  const errorHeader = (
    <ModalHeader className="alert-danger">
      {t('Oh no! There was an error.')}
    </ModalHeader>
  )

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      <Modal
        isOpen={visible}
        centered={true}
        backdrop={modalOptions.backdrop}
        toggle={toggle}
        size={modalOptions.size}
        scrollable={modalOptions.scrollable}
        style={{ padding: modalOptions.customPadding }}
      >
        {modalOptions.isError
          ? errorHeader
          : !modalOptions.hideHeader && (
              <ModalHeader
                toggle={toggle}
                className={`modal-provider ${classnames(
                  modalOptions.classNames
                )}`}
              >
                {modalOptions.title}
              </ModalHeader>
            )}
        <ModalBody>
          {modalOptions.innerComponent ? (
            modalOptions.innerComponent
          ) : (
            <div
              dangerouslySetInnerHTML={{ __html: modalOptions.message }}
            ></div>
          )}
          {modalOptions.isError && (
            <Alert className="mt-2" color="warning">
              <a
                style={{ color: 'black' }}
                href="https://promolayer.crisp.help/"
                target="_blank"
              >
                {t('If this error continues please contact support here.')}
              </a>
            </Alert>
          )}
        </ModalBody>
        {!modalOptions.hideFooter && (
          <ModalFooter>
            <Button color="warning" onClick={() => setVisible(false)}>
              {t('Close')}
            </Button>
          </ModalFooter>
        )}
      </Modal>
      <style jsx>{`
        :global(.modal-provider.modal-header) {
          background-color: #f3f3f9;
        }
        :global(.modal-provider.modal-header .close) {
          top: 15px;
          right: 15px;
        }
        :global(.no-title-close .close) {
          top: 15px;
          right: 20px;
        }
        :global(.modal.show) {
          background-color: ${modalOptions.backdrop
            ? 'rgba(0, 0, 0, 0)'
            : 'rgb(0,0,0,0.3)'};
        }
        :global(.modal-provider.modal-header.success) {
          background-color: green;
          color: #fff;
        }
      `}</style>
    </ModalContext.Provider>
  )
}

const useModal = () => useContext(ModalContext)

const ToastContext = createContext()
let toastTimeout

const baseToastOptions = {
  title: '',
  message: '',
  isError: false,
  isSuccess: false,
  isWarning: false,
  isInfo: false,
  timeout: 5000,
  countdown: 0
}

const ToastProvider = ({ children }) => {
  const [visible, setVisible] = useState(false)
  const [toastOptions, setToastOptions] = useState({
    title: '',
    message: '',
    isError: false,
    isSuccess: false,
    isWarning: false,
    isInfo: false,
    timeout: 5000,
    countdown: 0
  })
  const { t } = useTranslation()

  const toggle = () => {
    setVisible(!visible)
  }

  const showToast = (options) => {
    if (toastTimeout) {
      setVisible(false)
      clearTimeout(toastTimeout)
    }

    setToastOptions({ ...baseToastOptions, ...options })

    toastTimeout = setTimeout(() => {
      toastTimeout = null
      setVisible(false)
    }, toastOptions.timeout)
    setTimeout(() => {
      setVisible(true)
    }, 100)
  }

  const hideToast = () => {
    setVisible(false)
  }

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      {children}
      {visible && toastOptions.message ? (
        <>
          <Toast
            isOpen={visible}
            className={classnames({
              success: toastOptions.isSuccess,
              danger: toastOptions.isError,
              warning: toastOptions.isWarning
            })}
            style={{ position: 'fixed', bottom: '15px', right: '15px' }}
          >
            {toastOptions.isError ? (
              <ToastHeader toggle={toggle}>
                {t('Oh no! There was an error.')}
              </ToastHeader>
            ) : (
              <ToastHeader toggle={toggle}>
                {toastOptions.isSuccess && (
                  <i className="mdi mdi-check me-2 text-white" />
                )}{' '}
                {toastOptions.title}
              </ToastHeader>
            )}
            <ToastBody>
              {toastOptions.message}
              {toastOptions.isError && (
                <Alert className="mt-2" color="warning">
                  <a
                    target="_blank"
                    href="https://promolayer.crisp.help/"
                    style={{ color: 'black' }}
                  >
                    {t('If this error continues please contact support here.')}
                  </a>
                </Alert>
              )}
              {toastOptions.countdown > 0 && (
                <div className="countdown mt-2"></div>
              )}
            </ToastBody>
          </Toast>
          <style jsx>
            {`
              :global(.success.toast) {
                background: #fff;
                color: #333;
              }
              :global(.success .toast-header) {
                background: #34c38f;
                color: #fff;
              }
              :global(.danger .toast-header) {
                background: #f46a6a;
                color: #fff;
              }
              :global(.warning .toast-header) {
                background: #ffc107;
                color: #fff;
              }
              :global(.success.toast .close) {
                color: #fff;
                fill: #fff;
              }
              .countdown {
                animation-duration: ${toastOptions.countdown}ms;
                animation-name: countdownwidth;
                animation-fill-mode: forwards;
                background: #34c38f;
                border-radius: 10px;
                height: 10px;
                font-size: 10px;
                color: #fff;
              }
              @keyframes countdownwidth {
                0% {
                  width: 100%;
                }
                100% {
                  width: 0;
                }
              }
            `}
          </style>
        </>
      ) : null}
    </ToastContext.Provider>
  )
}

const useToast = () => useContext(ToastContext)

export { useToast, useModal, ToastProvider, ModalProvider }
