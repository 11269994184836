import FirestoreModel from './FirestoreModel'

class CouponCode extends FirestoreModel {
  constructor(firestore, collection) {
    super(firestore)
    this.setCollection(collection)
  }

  async getAllInCouponPoolId(couponPoolId) {
    const collection = this.firestore().collection(this.collectionName)
    const snapshot = await collection
      .where('couponId', '==', couponPoolId)
      .get()
    const rtn = []

    snapshot.forEach((doc) => {
      rtn.push({ id: doc.id, ...doc.data() })
    })

    return rtn
  }
}

export default CouponCode
