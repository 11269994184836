import Link from 'next/link'
import { useTranslation } from 'react-i18next'
import { Badge } from 'reactstrap'

import LanguageDropdown from './LanguageDropdown'
import PromolayerLogo from './common/PromolayerLogo'

const Header = () => {
  const { t } = useTranslation()

  return (
    <header id="page-topbar">
      <nav
        className="navbar-header navbar-dark d-flex justify-content-between align-items-center px-5"
        suppressHydrationWarning
      >
        <div
          suppressHydrationWarning={true}
          className="logo-wrapper d-none d-xxl-flex"
          style={{ flexBasis: '165px' }}
        >
          <PromolayerLogo />
        </div>
        <div className="navbar-nav d-flex">
          <div className="navbar-main-menu">
            <div className="d-flex w-100">
              <Link href="" legacyBehavior>
                <div
                  className={`nav-link d-flex align-items-center me-2 active`}
                >
                  <i className="mdi mdi-monitor-star mdi-18px me-xl-2" />
                  <span className="text-nowrap">{t('Displays')}</span>
                </div>
              </Link>
              <Link href="#" legacyBehavior>
                <div className="position-relative">
                  <div
                    className={`nav-link with-badge d-flex align-items-center me-2`}
                    onClick={(e) => null}
                  >
                    <i className="mdi mdi-flask mdi-18px  me-xl-2" />
                    <span className="text-nowrap">{t('A/B test')}</span>
                  </div>
                </div>
              </Link>
              <Link href="#" legacyBehavior>
                <div className={`nav-link d-flex align-items-center me-2`}>
                  <i className="mdi mdi-view-list-outline mdi-18px  me-xl-2" />
                  <span className="text-nowrap">{t('Lists')}</span>
                </div>
              </Link>
              <Link href="#" legacyBehavior>
                <div className={`nav-link d-flex align-items-center me-2`}>
                  <i className="mdi mdi-ticket-percent-outline mdi-18px  me-xl-2" />
                  <span className="text-nowrap">{t('Coupon history')}</span>
                </div>
              </Link>
              <Link href="#" legacyBehavior>
                <div
                  className="nav-link d-flex align-items-center me-2"
                  onClick={(e) => null}
                >
                  <i className="mdi mdi-email-edit-outline mdi-18px  me-xl-2" />
                  <span className="text-nowrap">{t('Email templates')}</span>
                </div>
              </Link>
              <a
                className="nav-link align-items-center d-flex search-help-icon"
                onClick={() => $crisp.push(['do', 'helpdesk:search'])}
              >
                <i className="mdi mdi-magnify mdi-18px me-2" />{' '}
                <span className="d-none d-xxl-inline">{t('Help')}</span>
              </a>
            </div>
          </div>
          <div className="navbar-user-menu">
            <Badge
              color="info"
              className="p-2 me-3"
              style={{ textTransform: 'capitalize' }}
            >
              {t('Free')}
            </Badge>
            <LanguageDropdown />
            <div className="d-inline-block dropdown">
              <button
                id="page-header-user-dropdown"
                aria-haspopup="true"
                className="btn header-item waves-effect d-flex align-items-center"
                aria-expanded="false"
              >
                <span
                  className="d-inline-block ms-1 text-nowrap overflow-hidden"
                  style={{ maxWidth: '130px' }}
                >
                  <i className="mdi mdi-account-circle-outline font-size-20 align-middle ms-1"></i>
                </span>
                <i className="mdi mdi-chevron-down d-inline-block"></i>
              </button>
              <div
                tabIndex="-1"
                role="menu"
                className="dropdown-menu dropdown-menu-right"
                style={{
                  position: 'absolute',
                  top: '0px',
                  left: '0px',
                  transform: 'translate3d(-131px, 55px, 0px)'
                }}
              ></div>
            </div>
          </div>
        </div>
      </nav>
      <style jsx>
        {`
          .with-badge {
            position: relative;
          }

          .badge {
            position: absolute;
            top: -3px;
            left: 50%;
            transform: translate(-50%, 0);
          }

          .nav-link.nav-link.nav-link {
            padding-left: 10px;
            padding-right: 10px;
          }

          .nav-link.active {
            background: #856fcf;
            border-radius: 5px;
          }
        `}
      </style>
      <style jsx global>
        {`
          #usageIndicator .bx-bell::before {
            font-size: 1.5em;
            vertical-align: middle;
            padding-right: 5px;
          }

          .btn-hollow-custom {
            background-color: transparent;
            border: 1px solid #fff;
            color: #fff;
            cursor: pointer;
          }
        `}
      </style>
    </header>
  )
}

export default Header
