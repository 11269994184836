import classnames from 'classnames'
import _ from 'lodash'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DropdownItem, DropdownMenu, UncontrolledDropdown } from 'reactstrap'
import { Badge } from 'reactstrap'

import { useAuth } from '../../providers/AuthProvider'
import { useFirebase } from '../../providers/FirebaseProvider'
import { useWorkspace } from '../../providers/WorkspaceProvider'

const WorkspaceDropdown = ({ toggle }) => {
  const { t } = useTranslation()
  const {
    currentWorkspace,
    setCurrentWorkspace,
    workspaces,
    isWorkspaceOwner,
    workspaceLimit
  } = useWorkspace()
  const { firebase } = useFirebase()
  const { userData, currentPlan } = useAuth()
  const router = useRouter()
  const [filteredWorkspaces, setFilteredWorkspaces] = useState(workspaces)

  const largeList = workspaces.length > 14

  const showCurrentWorkspace = () => {
    return (
      workspaces.find((item) => item.id === currentWorkspace.id)?.name ?? ''
    )
  }

  const onWorkspaceClick = (e, id) => {
    e.preventDefault()
    if (id !== currentWorkspace.id) {
      const current = workspaces.find((ws) => ws.id === id)
      setCurrentWorkspace({ ...current })
      firebase.workspace = current
    }
    toggle()
  }

  const onDropdownOutsideClick = (e) => {
    e.stopPropagation()
    e.preventDefault()

    if (e.target.classList.contains('dropdown-item')) {
      toggle()
    }
  }

  const goTo = (url) => {
    toggle()
    router.push(url)
  }

  // returns list of workspaces filtered by workspace.name or all if filter is ''
  const updateFilter = (e) => {
    const filterText = e.target.value
    if (filterText && filterText.length > 0) {
      setFilteredWorkspaces(
        workspaces.filter((item) =>
          item.name.toLowerCase().includes(filterText.toLowerCase())
        )
      )
    } else {
      setFilteredWorkspaces(workspaces)
    }
  }

  return (
    <UncontrolledDropdown
      className={`workspace-dropdown ${largeList && 'large-list'}`}
      direction="left"
    >
      <div className="text-nowrap d-flex align-items-center">
        <i className="mdi mdi-chevron-left"></i>
        <div className="ms-2">
          <small>{t('Current Workspace')}</small>
          <br />
          {showCurrentWorkspace()}
        </div>
      </div>
      <DropdownMenu onClick={onDropdownOutsideClick}>
        <DropdownItem header>
          {!largeList && <span>{t('Workspaces')}</span>}
          {largeList && (
            <div className="filter">
              <input
                type="text"
                onChange={updateFilter}
                className="form-control"
                placeholder={t('Search...')}
              />
            </div>
          )}
        </DropdownItem>
        {_.orderBy(filteredWorkspaces, 'name', 'desc').map((workspace) => (
          <DropdownItem key={workspace.id}>
            <a
              style={{ textOverflow: 'ellipsis' }}
              className={classnames({
                'd-block': true,
                'w-100': true,
                'overflow-hidden': true,
                'fw-bold': workspace.id === currentWorkspace.id
              })}
              onClick={(e) => onWorkspaceClick(e, workspace.id)}
            >
              {workspace.name}
              {userData.userId !== workspace.userId && (
                <Badge className="ms-2" color="info">
                  {t('Invited')}
                </Badge>
              )}
            </a>
          </DropdownItem>
        ))}
        <DropdownItem divider />
        <div className="buttons bottom-buttons">
          <div className="d-flex">
            {workspaces.length < workspaceLimit && (
              <div
                className="text-center workspace-add flex-grow-1"
                onClick={(e) => goTo('/workspaces/create')}
              >
                <i className="mdi mdi-plus"></i> {t('Add')}
              </div>
            )}
            <div
              className="text-center workspace-manage flex-grow-1"
              onClick={(e) => goTo('/workspaces')}
            >
              <i className="mdi mdi-pencil"></i> {t('Manage')}
            </div>
          </div>
        </div>
      </DropdownMenu>
      <style jsx>{`
        .buttons {
          color: #5c3dc2 !important;
        }

        .workspace-add {
          border-right: 1px solid #eff2f7;
        }
        :global(.workspace-dropdown.large-list .dropdown-menu) {
          max-height: 500px;
          overflow: auto;
          padding-bottom: 0;
        }
        :global(.workspace-dropdown.large-list .dropdown-divider) {
          display: none;
        }
        :global(.workspace-dropdown.large-list .bottom-buttons) {
          position: sticky;
          bottom: 0;
          width: 100%;
          background: #fff;
          border-top: 1px solid #eff2f7;
          padding: 10px 0;
        }
      `}</style>
    </UncontrolledDropdown>
  )
}

export default WorkspaceDropdown
