import Cookies from 'js-cookie'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap'

import { posthogEvent } from '../lib/helpers'
import { useAuth } from '../providers/AuthProvider'
import { useFirebase } from '../providers/FirebaseProvider'
import { usePlatform } from '../providers/PlatformProvider'
import UsageBar from './common/UsageBar'
import WorkspaceDropdown from './common/WorkspaceDropdown'

const ProfileMenu = () => {
  const [menu, setMenu] = useState(false)
  const { t } = useTranslation()
  const { firebase } = useFirebase()
  const { viewCountAndLimit, userData } = useAuth()
  const { push } = useRouter()
  const { platform, toggleInstallationModal, goPricePage } = usePlatform()

  const toggle = () => {
    setMenu(!menu)
  }

  return (
    <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block">
      <DropdownToggle
        className="btn header-item waves-effect d-flex align-items-center"
        id="page-header-user-dropdown"
        tag="button"
      >
        <span
          className="d-inline-block ms-1 text-nowrap overflow-hidden"
          style={{ maxWidth: '130px', textOverflow: 'ellipsis' }}
        >
          <i className="mdi mdi-account-circle-outline font-size-20 align-middle ms-1" />
        </span>
        <i className="mdi mdi-chevron-down d-inline-block" />
      </DropdownToggle>
      <DropdownMenu end>
        <div className="status-area">
          <span className="user-name">{platform.getDisplayName()}</span>
          <div
            onClick={() => push(platform.getPricingUrl())}
            style={{ cursor: 'pointer' }}
          >
            <UsageBar viewCountAndLimit={viewCountAndLimit} />
          </div>
        </div>
        {platform.showProfile && (
          <DropdownItem tag="div">
            <Link href="/profile" legacyBehavior>
              <a>
                <i className="mdi mdi-account-circle-outline font-size-16 align-middle ms-1" />{' '}
                {t('Profile')}
              </a>
            </Link>
          </DropdownItem>
        )}
        {(platform.showWorkspace || userData.admin) && (
          <WorkspaceDropdown toggle={toggle} />
        )}
        {platform.showInstall && (
          <DropdownItem tag="div">
            <a onClick={() => toggleInstallationModal()}>
              <i className="mdi mdi-code-braces font-size-16 align-middle ms-1" />{' '}
              {t('Promolayer Installation')}
            </a>
          </DropdownItem>
        )}

        {platform.showEmbedCode && (
          <DropdownItem tag="div">
            <Link href="/install" legacyBehavior>
              <a>
                <i className="mdi mdi-code-braces font-size-16 align-middle ms-1" />{' '}
                {platform.type === 'shopify'
                  ? t('Post purchase page')
                  : t('Install code')}
              </a>
            </Link>
          </DropdownItem>
        )}
        {userData.admin && [
          <DropdownItem tag="div" key="impersonate">
            <Link href="/impersonate" legacyBehavior>
              <a>
                <i className="mdi mdi-account-circle font-size-16 align-middle ms-1" />{' '}
                {t('Impersonate')}
              </a>
            </Link>
          </DropdownItem>,
          <DropdownItem tag="div" key="copy-display">
            <Link href="/copyDisplay" legacyBehavior>
              <a>
                <i className="mdi mdi-content-copy font-size-16 align-middle ms-1" />{' '}
                {t('Copy display')}
              </a>
            </Link>
          </DropdownItem>,
          <DropdownItem tag="div" key="copy-test">
            <Link href="/copyTest" legacyBehavior>
              <a>
                <i className="mdi mdi-content-copy font-size-16 align-middle ms-1" />{' '}
                {t('Copy AB test')}
              </a>
            </Link>
          </DropdownItem>,
          <DropdownItem tag="div" key="template-creator">
            <Link href="/template-creator" legacyBehavior>
              <a>
                <i className="mdi mdi-pencil font-size-16 align-middle ms-1" />{' '}
                {t('Template creator')}
              </a>
            </Link>
          </DropdownItem>,
          <DropdownItem tag="div" key="template-manager">
            <Link href="/template-manager" legacyBehavior>
              <a>
                <i className="mdi mdi-clipboard-edit font-size-16 align-middle ms-1" />{' '}
                {t('Template manager')}
              </a>
            </Link>
          </DropdownItem>
        ]}
        <DropdownItem tag="div">
          <a onClick={() => goPricePage()}>
            <i className="mdi mdi-diamond-stone font-size-16 align-middle ms-1" />{' '}
            {t('Upgrades & Plans')}
          </a>
        </DropdownItem>
        <DropdownItem tag="div" key="help-link">
          <Link href="https://promolayer.crisp.help/" legacyBehavior>
            <a target="_blank">
              <i className="mdi mdi-help-circle-outline font-size-16 align-middle ms-1" />{' '}
              {t('Helpdesk')}
            </a>
          </Link>
        </DropdownItem>
        {(platform.showLogout ||
          Cookies.get('impersonate-login') ||
          userData.admin) && (
          <>
            <div className="dropdown-divider" />
            <a
              href=""
              className="dropdown-item"
              onClick={(e) => {
                e.preventDefault()
                posthogEvent({ eventType: 'reset' })
                firebase.logout().then(async () => {
                  Cookies.remove('impersonate-login')
                  platform
                    .getUrlAfterLogout()
                    .then((url) => {
                      firebase.user = null
                      if (window.self !== window.top) {
                        window.top.location.href = url
                      } else {
                        location.href = url
                      }
                    })
                    .catch((e) => {
                      console.log(e)
                    })
                })
              }}
            >
              <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />{' '}
              <span>{t('Logout')}</span>
            </a>
          </>
        )}
        <style jsx>{`
          .status-area {
            background: #f8f9fa;
            padding: 12px 24px;
            color: #444;
            margin-top: -8px;
          }

          .user-name {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
            margin-bottom: 3px;
          }

          .status-area :global(.user-usage) {
            font-size: 10px;
          }

          .status-area :global(.progress) {
            height: 5px;
            background: #ccc;
          }

          .dropdown-divider:first-child {
            display: none;
          }
        `}</style>

        <style jsx global>{`
          .workspace-dropdown {
            padding: 0.35rem 1.5rem;
            cursor: pointer;
            color: #5c3dc2;
          }

          .workspace-dropdown:hover > div.dropdown-menu {
            display: block;
            position: absolute;
            left: -200px !important;
            top: -50px !important;
            width: 200px;
          }

          .workspace-dropdown .dropdown-item {
            color: #5c3dc2 !important;
          }

          .workspace-dropdown .dropdown-item:hover {
            background-color: unset;
          }
        `}</style>
      </DropdownMenu>
    </Dropdown>
  )
}

export default ProfileMenu
