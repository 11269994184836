import classnames from 'classnames'
import { AuthAction, withAuthUser } from 'next-firebase-auth'
import Head from 'next/head'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

import { sendToLogDNA, shouldHideHeader } from '../lib/helpers'
import { isDisplayView } from '../lib/helpers'
import { AuthProvider } from '../providers/AuthProvider'
import { DisplayDataProvider } from '../providers/DisplayDataProvider'
import { FirebaseProvider } from '../providers/FirebaseProvider'
import { PlatformProvider } from '../providers/PlatformProvider'
import { WorkspaceProvider } from '../providers/WorkspaceProvider'
import { getFirebaseHandler } from '../utils/firebaseHandler'
import { useVersionDetector } from '../utils/hooks/version'
import Header from './Header'
import RefreshOnUpdatedBanner from './RefreshOnUpdatedBanner'

const PrivateLayout = ({ children, error, title = 'Displays' }) => {
  const { pathname, isReady } = useRouter()
  const firebase = getFirebaseHandler()
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, { title })
  )

  const { versionUpdated } = useVersionDetector()

  return (
    <FirebaseProvider firebase={firebase}>
      <AuthProvider>
        <PlatformProvider>
          <WorkspaceProvider>
            <Head>
              <title>{title}</title>
            </Head>
            {versionUpdated && <RefreshOnUpdatedBanner />}
            {isReady && (
              <>
                {!shouldHideHeader(pathname) && <Header title={title} />}
                <main
                  className={classnames({
                    'main-content': true,
                    'no-header': shouldHideHeader(pathname)
                  })}
                >
                  {isDisplayView(pathname) ? (
                    <DisplayDataProvider>
                      {error ? <div>{error}</div> : childrenWithProps}
                    </DisplayDataProvider>
                  ) : error ? (
                    <div>{error}</div>
                  ) : (
                    childrenWithProps
                  )}
                </main>
              </>
            )}
          </WorkspaceProvider>
        </PlatformProvider>
      </AuthProvider>
    </FirebaseProvider>
  )
}
export default withAuthUser({
  whenUnauthedBeforeInit: AuthAction.RENDER
  //whenUnauthedAfterInit: AuthAction.REDIRECT_TO_LOGIN,
  //whenAuthed:AuthAction.REDIRECT_TO_APP,
  //LoaderComponent: FullPageLoader,
})(PrivateLayout)
