import React from 'react'

const Spinner = (props) => {
  return (
    <>
      <div className="plmw-spinner">
        <div className="plmw-bounce1"></div>
        <div className="plmw-bounce2"></div>
        <div className="plmw-bounce3"></div>
      </div>
      <style jsx>
        {`
          .plmw-spinner {
            margin: 0 auto;
            width: 70px;
            text-align: center;
          }

          .plmw-spinner > div {
            width: 12px;
            height: 12px;
            background-color: rgba(
              ${props.color.r},
              ${props.color.g},
              ${props.color.b},
              ${props.color.a}
            );

            border-radius: 100%;
            display: inline-block;
            -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            animation: sk-bouncedelay 1.4s infinite ease-in-out both;
          }

          .plmw-spinner .plmw-bounce1 {
            -webkit-animation-delay: -0.32s;
            animation-delay: -0.32s;
          }

          .plmw-spinner .plmw-bounce2 {
            -webkit-animation-delay: -0.16s;
            animation-delay: -0.16s;
          }

          @-webkit-keyframes sk-bouncedelay {
            0%,
            80%,
            100% {
              -webkit-transform: scale(0);
            }
            40% {
              -webkit-transform: scale(1);
            }
          }

          @keyframes sk-bouncedelay {
            0%,
            80%,
            100% {
              -webkit-transform: scale(0);
              transform: scale(0);
            }
            40% {
              -webkit-transform: scale(1);
              transform: scale(1);
            }
          }
        `}
      </style>
    </>
  )
}

Spinner.defaultProps = {
  color: {
    r: 255,
    g: 255,
    b: 255,
    a: 1
  }
}

export default Spinner
