import Cookies from 'js-cookie'
import { useAuthUser } from 'next-firebase-auth'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from 'reactstrap'

import {
  getUsageLimit,
  initializeMisc,
  updateUsage
} from '../lib/authUserHelper'
import { sendToLogDNA } from '../lib/helpers'
import PlatformHelperFactory from '../utils/PlatformHelper'
import { PLANS_ORDERED, purgeCache } from '../utils/common'
import {
  changeEmailsInKlaviyo,
  sendDataToCrisp,
  sendDataToKlaviyo
} from '../utils/userDataSync'
import { useFirebase } from './FirebaseProvider'

const AuthContext = createContext()

let logoutProcessStarted = false,
  unsubscribe = null,
  oldUserData = null,
  oldPlan = null

function AuthProvider({ children }) {
  const [viewCountAndLimit, setViewCountAndLimit] = useState({
    viewCount: 0,
    limit: 1000
  })
  const [userData, setUserData] = useState(null)
  const authUser = useAuthUser()
  const { firebase } = useFirebase()
  const { t } = useTranslation()

  function processLogout(e) {
    if (logoutProcessStarted) return
    logoutProcessStarted = true
    firebase.logout().catch(console.error)
    authUser.signOut().catch(console.error)
    //Navigate to the login screen
    if (userData) {
      const platformHelper = new PlatformHelperFactory({ userData })
      platformHelper
        .getUrlAfterLogout()
        .then((url) => {
          setUserData(null)
          if (window.self !== window.top) {
            window.top.location.href = url
          } else {
            location.href = url
          }
        })
        .catch(console.error)
        .finally(() => {
          logoutProcessStarted = false
        })
    } else {
      if (e) {
        sendToLogDNA(
          `Failed to get userData: ${authUser.id ?? 'No user id'} - ${
            e.message
          }`
        )
      } else {
        sendToLogDNA(`Failed to get userData: ${authUser.id ?? 'No user id'}`)
      }

      const platform = localStorage.getItem('pl-login-platform')
      if (platform === 'shopify') {
        window.top.location.href = `https://admin.shopify.com/`
      } else if (platform === 'colorme') {
        window.location.href = `/colorme/login`
      } else {
        window.location.href = `/login`
      }
      logoutProcessStarted = false
    }
  }

  function onUserDataChanged(userData) {
    //Uninstalled
    if (userData.deletedAt) {
      processLogout()
    }

    setUserData(userData)
  }

  useEffect(() => {
    if (!userData) return

    if (oldUserData && oldUserData.email !== userData.email) {
      changeEmailsInKlaviyo({
        oldEmail: oldUserData.email,
        newEmail: userData.email
      }).catch()
    } else {
      sendDataToKlaviyo(userData.email, userData).catch()
      sendDataToCrisp(userData.email, userData).catch()
    }
    oldUserData = userData
  }, [userData])

  function isCurrentPlanMoreThan(target) {
    let activePlan = (userData.platform?.currentPlan ?? 'Free').toLowerCase()

    if (userData.userId == 'urHyamjvm6c7DDJ8hcyd4AfzN7U2') {
      //This is another account of navitime
      activePlan = 'plus level 1'
    }

    const currentRank = PLANS_ORDERED.find(
      (plan) => plan.name === activePlan
    ).rank
    const targetRank = PLANS_ORDERED.find(
      (plan) => plan.name === target.toLowerCase()
    ).rank

    return currentRank >= targetRank
  }

  function BlockedUserNotice() {
    const blockedUsers = ['C7Yea56U1iTn6HJp8ISoUeOJa682']

    if (blockedUsers.includes(authUser?.id)) {
      return (
        <Alert color={'danger'} className="m-0">
          <span className="block sm:inline">
            {t(
              'Due to high traffic and no active popups your account has been paused. Please contact live chat to re-start the account.'
            )}
          </span>
        </Alert>
      )
    }

    return null
  }

  async function initAuth() {
    const userData = await firebase.getUserData(authUser.id)
    setUserData(userData)
    firebase.setUserHandler(authUser, setUserData)
    //Set firebase listener
    unsubscribe = firebase.listenUserDataChanges(authUser.id, onUserDataChanged)

    if (!Cookies.get('impersonate-login')) initializeMisc(authUser, userData)
  }

  useEffect(() => {
    if (!authUser.clientInitialized) return

    if (authUser.id) {
      //Initialize auth
      initAuth().catch((e) => {
        processLogout(e)
      })
    } else {
      if (typeof unsubscribe === 'function') unsubscribe()

      //Navigate the user to login screen
      processLogout()
    }
  }, [authUser])

  const getUsageLimitHandle = async () => {
    //Set usage and limit
    const [usage, error] = await getUsageLimit(authUser.id)
    if (!error) {
      setViewCountAndLimit(usage)
      //Update firebase usage record
      await updateUsage(authUser.id, usage.usagePercent)

      //Purge cache everytime usage is loaded
      if (oldPlan && oldPlan !== userData.platform.currentPlan)
        purgeCache(authUser.id, false, true)

      oldPlan = userData.platform.currentPlan
    }
  }

  //Get usage limit and update usage record on Firebase
  useEffect(() => {
    if (userData?.platform?.currentPlan) getUsageLimitHandle()
  }, [userData?.platform?.currentPlan])

  return (
    authUser.id &&
    userData && (
      <AuthContext.Provider
        value={{
          userData,
          authUser,
          currentPlan: userData?.platform?.currentPlan ?? 'Free',
          viewCountAndLimit,
          isCurrentPlanMoreThan
        }}
      >
        <BlockedUserNotice />
        {children}
      </AuthContext.Provider>
    )
  )
}

const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
