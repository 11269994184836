import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Col, Row } from 'reactstrap'

import FullPageLoader from './FullPageLoader'
import Navbar from './Navbar'
import SkeletonHeader from './SkeletonHeader'
import Breadcrumb from './common/Breadcrumb'
import Spinner from './common/Spinner'
import DisplayCardDummy from './display-cards/DisplayCardDummy'

const FullPageSkeleton = ({ hide }) => {
  const { t, i18n } = useTranslation()

  const AppSearch = useMemo(() => {
    return (
      <Navbar menuOpen={false}>
        <div className="justify-content-between d-flex w-100">
          <form
            className="app-search"
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <div className="position-relative">
              <input
                type="text"
                className="form-control"
                placeholder={t('Filter...')}
                defaultValue={''}
                onChange={null}
              />
              <span className="bx bx-search-alt" />
            </div>
          </form>
          <Button
            className="add-display d-none d-sm-inline"
            color="primary"
            onClick={new Function()}
          >
            <i className="mdi mdi-plus-circle-outline"></i> {t('New display')}
          </Button>
        </div>
      </Navbar>
    )
  }, [])

  return hide ? null : (
    <>
      <SkeletonHeader />
      <main className="main-content">
        {AppSearch}
        <div className="container">
          <div className="page-content">
            <Breadcrumb
              showBackButton={false}
              title={t('Home')}
              titleLink="/"
              breadcrumbItem={t('Displays')}
              workspace={null}
            />
            <Row>
              <Col>
                <DisplayCardDummy />
              </Col>
            </Row>
          </div>
        </div>
      </main>
    </>
  )
}

export default FullPageSkeleton
