import axios from 'axios'

class PlatformHelper {
  ready = false
  type = undefined
  userData = {}
  oldUserData = {}
  supportsGeneratingCoupons = false
  supportAutoApplyCoupon = false
  usePlatformsCustomerList = false
  usePlatformsCustomerTag = false
  showEmbedCode = false
  showInstall = false
  showProfile = true
  showLogout = false
  useStripe = false
  useFirebaseLogin = false
  supportCartState = false
  supportTotalAmountOfCart = false
  supportUserTags = false
  showWorkspace = false
  urlAfterLogout = '/login'

  constructor({ userData, firebase, apiCall }) {
    this.userData = userData
    this.firebase = firebase
    this.apiCall = apiCall
    this.ready = true
  }

  setUserData(userData) {
    this.oldUserData = { ...this.userData }
    this.userData = userData
  }

  getPricingUrl() {
    return this.useStripe ? '/pricing' : `/${this.type}/pricing`
  }

  isAppEnabled() {
    return true
  }

  getDisplayName() {
    let fullName = this.userData.fullName
    if (!fullName && (this.userData.firstName || this.userData.lastName))
      fullName = `${this.userData.firstName} ${this.userData.lastName}`

    return fullName || this.userData.email
  }

  getUrl() {
    if (this.userData.installWebsite) {
      return this.userData.installWebsite.replace(/\/$/, '')
    }
    return ''
  }

  async getUrlAfterLogout() {
    return this.urlAfterLogout
  }

  setInvited() {
    this.type = 'invited'
    this.supportsGeneratingCoupons = false
    this.supportAutoApplyCoupon = false
    this.usePlatformsCustomerList = false
    this.showLogout = true
    this.urlAfterLogout = '/login'
    this.getDisplayName = () => {
      return this.userData.fullName
    }

    this.getPricingUrl = () => {
      return false
    }
  }

  async sync() {
    const timezone =
      Intl.DateTimeFormat()?.resolvedOptions()?.timeZone ?? 'Asia/Tokyo'
    const type = this.type === 'invited' ? 'generic' : this.type
    const { data } = await axios.post(`/api/${type}/syncInfo`, {
      userData: this.userData,
      timezone
    })
    return data
  }
}

class GenericPlatformHelper extends PlatformHelper {
  type = 'generic'
  showEmbedCode = true
  showProfile = true
  showLogout = true
  useStripe = true
  showWorkspace = true
  useFirebaseLogin = true
}

class WordpressPlatformHelper extends PlatformHelper {
  type = 'wordpress'
  showProfile = true
  showLogout = true
  useStripe = true
  showWorkspace = false
  useFirebaseLogin = true
}

class ShopifyPlatformHelper extends PlatformHelper {
  type = 'shopify'
  showInstall = true
  showEmbedCode = true
  supportsGeneratingCoupons = true
  supportAutoApplyCoupon = true
  usePlatformsCustomerList = true
  usePlatformsCustomerTag = true
  supportCartState = true
  supportTotalAmountOfCart = true
  supportUserTags = true

  isAppEnabled() {
    if (this.userData.skipAppEmbedCheck) return true

    return this.userData.platform.appEmbed
  }

  getDisplayName() {
    return (
      this.userData.fullName ||
      this.userData.userId.replace('.myshopify.com', '')
    )
  }

  async getUrlAfterLogout() {
    return `https://admin.shopify.com/`
  }
}

class WixPlatformHelper extends PlatformHelper {
  type = 'wix'
  showInstall = true
  supportsGeneratingCoupons = true
  supportAutoApplyCoupon = false
  usePlatformsCustomerList = true
  usePlatformsCustomerTag = false

  isAppEnabled() {
    return this.userData.platform.appEmbed
  }

  getDisplayName() {
    return this.userData.fullName || this.userData.platform.shop
  }

  async enableScript() {
    try {
      const result = await axios.post('/api/wix/embedScript', {
        userData: this.userData
      })
      return [result.data?.success, null]
    } catch (e) {
      return [null, e]
    }
  }
}

class EcwidPlatformHelper extends PlatformHelper {
  type = 'ecwid'
  supportsGeneratingCoupons = true
  supportAutoApplyCoupon = false
  usePlatformsCustomerList = true
  useStripe = true

  isAppEnabled() {
    return true
  }

  getDisplayName() {
    return this.userData.fullName
  }

  async enableScript() {
    try {
      const result = await axios.post('/api/wix/embedScript', {
        userData: this.userData
      })
      return result.data?.success
    } catch (e) {
      return false
    }
  }
}

class ColormePlatformHelper extends PlatformHelper {
  type = 'colorme'
  supportsGeneratingCoupons = false
  supportAutoApplyCoupon = false
  supportCartState = true
  supportTotalAmountOfCart = true
  usePlatformsCustomerList = true
  showLogout = true
  urlAfterLogout = '/colorme/login'

  getDisplayName() {
    return this.userData.fullName
  }

  getPricingUrl() {
    return 'https://app.shop-pro.jp/me/apps'
  }
}

class DummyPlatformHelper extends PlatformHelper {
  type = 'dummy'
  supportsGeneratingCoupons = false
  supportAutoApplyCoupon = false
  usePlatformsCustomerList = false
  showLogout = false
  urlAfterLogout = '/login'

  getPricingUrl() {
    return false
  }

  async sync() {
    return null
  }
}

export default function PlatformHelperFactory(data) {
  const platform = data.userData?.platform?.name ?? ''

  switch (platform) {
    case 'generic':
      return new GenericPlatformHelper(data)
    case 'wordpress':
      return new WordpressPlatformHelper(data)
    case 'shopify':
      return new ShopifyPlatformHelper(data)
    case 'wix':
      return new WixPlatformHelper(data)
    case 'ecwid':
      return new EcwidPlatformHelper(data)
    case 'colorme':
      return new ColormePlatformHelper(data)
    default:
      return new DummyPlatformHelper(data)
  }
}
