import React, { createContext, useContext, useEffect, useState } from 'react'

import ABTestModel from '../lib/models/ABTest'
import ApiKeyModel from '../lib/models/ApiKey'
import ContactModel from '../lib/models/Contact'
import CouponCodeModel from '../lib/models/CouponCode'
import CouponHistoryModel from '../lib/models/CouponHistory'
import CouponPoolModel from '../lib/models/CouponPool'
import DisplayModel from '../lib/models/Display'
import EmailTemplateModel from '../lib/models/EmailTemplate'
import ListModel from '../lib/models/List'
import TemplateModel from '../lib/models/Template'
import UploadModel from '../lib/models/Upload'
import WorkspaceModel from '../lib/models/Workspace'

const FirebaseContext = createContext()

const FirebaseProvider = ({ children, firebase }) => {
  const firestore = firebase.getFirestore()
  const Display = new DisplayModel(firestore, 'popups')
  const ABTest = new ABTestModel(firestore, 'abTests')
  const Upload = new UploadModel(firestore, 'uploads')
  const Workspace = new WorkspaceModel(firestore, 'workspaces')
  const List = new ListModel(firestore, 'lists')
  const Contact = new ContactModel(firestore, 'emailList')
  const CouponPool = new CouponPoolModel(firestore, 'couponPools')
  const CouponCode = new CouponCodeModel(firestore, 'couponCodes')
  const EmailTemplate = new EmailTemplateModel(firestore, 'emailTemplates')
  const Template = new TemplateModel(firestore, 'templates')
  const CouponHistory = new CouponHistoryModel(firestore, 'couponHistories')
  const ApiKey = new ApiKeyModel(firestore, 'apiHashes')

  return (
    <FirebaseContext.Provider
      value={{
        firebase,
        Display,
        ABTest,
        Upload,
        Workspace,
        List,
        Contact,
        CouponPool,
        CouponCode,
        EmailTemplate,
        Template,
        CouponHistory,
        ApiKey
      }}
    >
      {children}
    </FirebaseContext.Provider>
  )
}

const useFirebase = () => useContext(FirebaseContext) ?? {}
export { useFirebase, FirebaseProvider }
