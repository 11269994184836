import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'

import { usePlatform } from '../../providers/PlatformProvider'

const NotEnabledBar = () => {
  const { t } = useTranslation()
  const { platform, toggleInstallationModal } = usePlatform()

  return (
    <div
      className="d-flex justify-content-center align-items-center py-2"
      style={{ backgroundColor: '#F46A6A', color: 'white' }}
    >
      <i className="mdi mdi-bell mdi-24px"></i>
      <div className="ms-2">
        <strong>{t('Promolayer is not enabled.')}</strong>{' '}
        {t('Displays will not show on your site.')}
      </div>
      <Button
        onClick={(e) => toggleInstallationModal()}
        style={{ backgroundColor: '#F46A6A', borderColor: 'white' }}
        className="ms-3 py-1 px-4"
      >
        {t('Fix this')}
      </Button>
    </div>
  )
}

export default NotEnabledBar
