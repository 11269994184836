import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from 'reactstrap'

import { useAuth } from '../../../providers/AuthProvider'
import { useFirebase } from '../../../providers/FirebaseProvider'
import { usePlatform } from '../../../providers/PlatformProvider'

const WixInstallationModal = () => {
  const { t } = useTranslation()
  const { platform, toggleInstallationModal } = usePlatform()
  const { userData } = useAuth()
  const { firebase } = useFirebase()

  const clicked = async () => {
    const [success, error] = await platform.enableScript()
    if (!error) {
      userData.platform.appEmbed = true
      await firebase.updateUserData(userData)
      toggleInstallationModal()
    } else {
      if (error.response.data.error.includes('connected domain')) {
        alert(
          t(
            'Embedded script apps can only be added by the owners of premium Wix sites with connected domains.'
          )
        )
      }
    }
  }

  return (
    <div
      className="d-flex align-center flex-column justify-content-center"
      style={{ minHeight: '200px' }}
    >
      <h3 className="text-center my-3">{t('Promolayer Installation')}</h3>
      <p>
        {t(
          'Before Promolayer can function a script must be embedded into your website. This script loads your popups and shows them to your visitors.'
        )}
      </p>
      <span>{t('Note')}:</span>
      <p
        dangerouslySetInnerHTML={{
          __html: t(
            'Embedded script apps can only be added by the owners of premium Wix sites with connected domains. Besides, your store must have https enabled.'
          )
        }}
      ></p>
      <div className="mt-4">
        <div className="mt-4 text-center">
          <Button color="primary" onClick={clicked}>
            {t('Enable Promolayer')}
          </Button>
          <Button
            className="ms-2"
            color="warning"
            onClick={toggleInstallationModal}
          >
            {t('Do it later')}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default WixInstallationModal
