import Link from 'next/link'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { BreadcrumbItem, Button, Col, Row } from 'reactstrap'

const Breadcrumb = (props) => {
  const { t, i18n } = useTranslation()
  const router = useRouter()
  const canBack = () => {
    if (typeof window === 'undefined') return true
    return window.history.length !== 1
  }
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          {props.showBackButton && canBack() && (
            <Button onClick={() => router.back()}>
              <i className="mdi mdi-chevron-double-left"></i> {t('Back')}
            </Button>
          )}
          <div>
            <strong className="mb-0 font-size-18">
              {props.breadcrumbItem}
            </strong>
            <span className="ms-2">
              {props.workspace &&
                !props.workspace.default &&
                `${t('Workspace')}: ${props.workspace.name}`}
            </span>
          </div>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              {props.title && (
                <BreadcrumbItem>
                  <Link href={props.titleLink} legacyBehavior>
                    {props.title}
                  </Link>
                </BreadcrumbItem>
              )}
              {props.breadcrumbItem && (
                <BreadcrumbItem active>
                  <Link href="#" legacyBehavior>
                    {props.breadcrumbItem}
                  </Link>
                </BreadcrumbItem>
              )}
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
  titleLink: PropTypes.string,
  showBackButton: PropTypes.bool
}

export default Breadcrumb
