import classnames from 'classnames'
import Head from 'next/head'
import { useRouter } from 'next/router'
import PropTypes from 'prop-types'
import React from 'react'

import { FirebaseProvider } from '../providers/FirebaseProvider'
import { getFirebaseHandler } from '../utils/firebaseHandler'
import FullPageSkeleton from './FullPageSkeleton'
import PublicHeader from './PublicHeader'

const PublicLayout = ({ children, error, title = 'Login' }) => {
  const { pathname } = useRouter()

  const firebase = getFirebaseHandler()

  const hideHeader = () => {
    return pathname.startsWith('/register') || pathname.startsWith('/login')
  }

  const showSkeleton = () => {
    return (
      pathname.startsWith('/shopify/signin2') ||
      pathname.startsWith('/oauthLogin')
    )
  }

  return showSkeleton() ? (
    <>
      <FullPageSkeleton />
      {children}
    </>
  ) : (
    <>
      <FirebaseProvider firebase={firebase}>
        <Head>
          <title>{title}</title>
        </Head>
        <PublicHeader title={title} />
        <main className={classnames({ 'main-content': !hideHeader() })}>
          {error ? <div>{error}</div> : <div>{children}</div>}
        </main>
      </FirebaseProvider>
    </>
  )
}
export default PublicLayout

PublicLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  error: PropTypes.string
}
