import axios from 'axios'

function getSessionData(callback) {
  const pattern = /_ga_JZCXJ6JWLR=GS\d\.\d\.(.+?)(?:;|$)/
  const match = document.cookie.match(pattern)
  const parts = match?.[1].split('.') ?? []

  return {
    ga_session_id: parts.shift(),
    ga_session_number: parts.shift()
  }
}

async function send(data) {
  let { clientId, userId, events } = data.google
  let {
    twitterClickId = '',
    email = '',
    eventType = '',
    contents = []
  } = data.twitter
  let postHogData = data?.posthog ?? {}

  const BASE_URL = process.env.NEXT_PUBLIC_LIVE_URL

  if (!Array.isArray(events)) {
    events = [events]
  }

  if (typeof window !== 'undefined') {
    const sessionData = getSessionData()
    events.forEach((event) => {
      if (!event.params) {
        event.params = {}
      }
      event.params.timestamp_micros = Date.now()
      event.params.session_id = sessionData.ga_session_id
      event.params.session_number = sessionData.ga_session_number
    })
  }

  try {
    const res = await axios.post(`${BASE_URL}/api/measurement`, {
      google: {
        userId: userId,
        clientId: clientId,
        events: events
      },
      twitter: {
        eventType,
        twitterClickId: twitterClickId,
        email: email,
        contents
      },
      postHogData
    })

    return res.data
  } catch (e) {
    return [null, e]
  }
}

export default { send }
