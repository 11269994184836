import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import 'react-medium-image-zoom/dist/styles.css'

import ChromelessLayout from '../components/ChromelessLayout'
import '../components/Editor/Editor.css'
import PrivateLayout from '../components/PrivateLayout'
import PublicLayout from '../components/PublicLayout'
import { detectLang, loadOtherLibrary } from '../lib/helpers'
import { ModalProvider, ToastProvider } from '../providers/ModalProvider'
import { ValidationProvider } from '../providers/ValidationProvider'
import '../public/assets/scss/theme.scss'
import routeConfig from '../route-config.js'
import initAuth from '../utils/initAuth'
import i18n from './../i18n'

initAuth()

function App({ Component, pageProps }) {
  const { pathname, query, events } = useRouter()
  const routeInfo = getRouteInfo(pathname)
  let isPrivate = routeInfo?.private
  let isChromeless = routeInfo?.chromeless
  const [render, setRender] = useState(false)
  const [title, setTitle] = useState('')

  useEffect(() => {
    if (!pathname) return

    try {
      setRender(() => {
        //priority 1. The language user saved, 2. Depends on the language of user's locale, either en or ja, 3. default 'en'
        const currentLanguage = detectLang()
        if (i18n.language !== currentLanguage)
          i18n.changeLanguage(currentLanguage)

        setTitle(i18n.t(routeInfo?.title || 'No title'))

        return true
      })
    } catch (e) {
      console.error(e)
    }
  }, [pathname])

  useEffect(() => {
    //Only for the first time
    window.plUser = {}
    document.body.dataset.layout = 'horizontal'
    document.body.dataset.topbar = 'light'
    document.body.dataset.layoutSize = 'fluid'
    document.body.dataset.layoutScrollable = 'true'
    loadOtherLibrary()
  }, [])

  if (isChromeless) {
    return (
      <ChromelessLayout title={title}>
        <Component {...pageProps} />
      </ChromelessLayout>
    )
  }

  let layout = (
    <ValidationProvider>
      <ToastProvider>
        <ModalProvider>
          {isPrivate ? (
            <PrivateLayout title={title}>
              <Component {...pageProps} />
            </PrivateLayout>
          ) : (
            <PublicLayout title={title}>
              <Component {...pageProps} />
            </PublicLayout>
          )}
        </ModalProvider>
      </ToastProvider>
    </ValidationProvider>
  )

  return <>{render && layout}</>
}

function getRouteInfo(pathname) {
  if (routeConfig[pathname]) {
    return routeConfig[pathname]
  } else {
    const rootDir = pathname.match(/^\/[^/]*/)[0]
    const rootRoute = routeConfig[rootDir]
    let child = rootRoute?.children?.find((child) => {
      const regex = new RegExp(child.regex)
      return pathname.match(regex)
    })
    if (child) child.private = child.private || rootRoute.private
    return child
  }
}

export default App
