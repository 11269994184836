import classnames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { toReadableNumber } from '../../lib/helpers'

const UsageBar = ({ viewCountAndLimit }) => {
  const { t } = useTranslation()
  if (viewCountAndLimit) {
    let { limit, viewCount } = viewCountAndLimit

    if (limit > 0) {
      let usageRate = (viewCount / limit) * 100
      return (
        <>
          <div id="usageWrap">
            <span className="user-usage">
              {t('Usage')}: {usageRate.toFixed(1)}% (
              {toReadableNumber(+viewCount)}/{toReadableNumber(limit, true)})
            </span>
            <div className="progress">
              <div
                className={classnames({
                  'progress-bar': true,
                  'bg-warning': usageRate >= 80,
                  'bg-danger': usageRate >= 100
                })}
                style={{ width: `${usageRate.toFixed(1)}%` }}
              ></div>
            </div>
          </div>
        </>
      )
    }
  }
  return null
}

export default UsageBar
