import FirestoreModel from './FirestoreModel'

class Contact extends FirestoreModel {
  constructor(firestore, collection) {
    super(firestore)
    this.setCollection(collection)
  }

  async getAllInListInWorkspace(listId, workspaceId) {
    const collection = this.firestore().collection(this.collectionName)
    const snapshot = await collection
      .where('listId', '==', listId)
      .where('workspace', '==', workspaceId)
      .get()
    const rtn = []

    snapshot.forEach((doc) => {
      rtn.push({ ...doc.data(), id: doc.id })
    })

    return rtn
  }

  async getAllInListInUser(listId, userId) {
    const collection = this.firestore().collection(this.collectionName)
    const snapshot = await collection
      .where('listId', '==', listId)
      .where('userId', '==', userId)
      .get()
    const rtn = []

    snapshot.forEach((doc) => {
      rtn.push({ ...doc.data(), id: doc.id })
    })

    return rtn
  }
}

export default Contact
