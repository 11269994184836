import firebase from 'firebase/app'

import { uniqueId } from '../../utils/firebaseHandler'
import FirestoreModel from './FirestoreModel'

class Upload extends FirestoreModel {
  constructor(firestore, collection) {
    super(firestore)
    this.setCollection(collection)
  }

  uploadFile = async (file, userId) => {
    const storage = await firebase.storage()
    const storageRef = storage.ref()
    const fileName = file.name
    const ext = fileName.split('.').pop().toLowerCase()
    const accepts = ['gif', 'png', 'webp', 'jpg', 'jpeg', 'mp4']
    if (!accepts.includes(ext)) throw new Error('File type not supported')

    const imageRef = storageRef.child(`uploaded/${userId}/${uniqueId()}.${ext}`)

    var metadata = {
      cacheControl: 'public,max-age=86400'
    }
    const handler = await imageRef.put(file, metadata)
    return await handler.ref.getDownloadURL()
  }

  deleteFile = async (url, userId) => {
    const storage = await firebase.storage()
    const storageRef = storage.ref()
    const fileName = this.getFileNameFromUrl(url)

    if (fileName) {
      const fileRef = storageRef.child(`uploaded/${userId}/${fileName}`)

      // Delete the file
      await fileRef.delete()
    } else {
      throw new Error('File not found')
    }
  }

  getFileNameFromUrl = (url) => {
    if (/^https:\/\/firebasestorage/.test(url)) {
      const regex = new RegExp(
        /firebasestorage.googleapis.com\/(.*%2F){2}([^?]*)/
      )
      const res = regex.exec(url)
      return res && res[2]
    }
    return null
  }
}

export default Upload
