class FirestoreModel {
  firestore = null
  collection = null
  constructor(firestore) {
    this.firestore = firestore
  }

  setUserData(userData) {
    this.userData = userData
  }

  setCollection(collectionName) {
    this.collectionName = collectionName
    //this.collection = this.firestore.collection(collectionName)
  }

  async getAllInWorkspace(workspace) {
    const collection = this.firestore().collection(this.collectionName)
    const snapshot = await collection.where('workspace', '==', workspace).get()
    const rtn = []
    snapshot.forEach((doc) => {
      rtn.push({ ...doc.data(), id: doc.id })
    })
    return rtn
  }

  async getAllInUser(userId) {
    const collection = this.firestore().collection(this.collectionName)
    const snapshot = await collection.where('userId', '==', userId).get()
    const rtn = []
    snapshot.forEach((doc) => {
      rtn.push({ ...doc.data(), id: doc.id })
    })
    return rtn
  }

  async get(id) {
    const collection = this.firestore().collection(this.collectionName)
    const docRef = collection.doc(id)
    const doc = await docRef.get()
    return { ...doc.data(), id }
  }
  async getFromIds(ids) {
    const promises = []
    const collection = this.firestore().collection(this.collectionName)
    const rtn = []
    for (let id of ids) {
      const docRef = collection.doc(id)
      promises.push(docRef.get())
    }
    const docs = await Promise.all(promises)
    for (let doc of docs) {
      if (doc.exists) rtn.push({ ...doc.data(), id: doc.id })
    }
    return rtn
  }

  async create(data, duplicate = false) {
    const collection = this.firestore().collection(this.collectionName)
    if (!duplicate) {
      data.updatedAt = new Date().toISOString()
    }
    data.createdAt = new Date().toISOString()

    const docRef = await collection.add(data)
    await docRef.update({ id: docRef.id })
    return { ...data, id: docRef.id }
  }

  async createBatch(data) {
    const collection = this.firestore().collection(this.collectionName)
    let batch = this.firestore().batch(),
      count = 0

    const createdAt = new Date().toISOString()
    const updatedAt = new Date().toISOString()
    for (let item of data) {
      const id = collection.doc().id
      batch.set(collection.doc(id), { ...item, createdAt, updatedAt })
      count++

      if (count === 500) {
        await batch.commit()
        batch = this.firestore().batch()
        count = 0
      }
    }
    await batch.commit()
  }

  async update(data, useUpdate = false) {
    const cloned = { ...data }
    const collection = this.firestore().collection(this.collectionName)
    cloned.updatedAt = new Date().toISOString()
    const docRef = await collection.doc(data.id)
    if (useUpdate) {
      await docRef.update(cloned)
    } else {
      await docRef.set(cloned, { merge: true })
    }
    return cloned
  }

  async delete(id) {
    const collection = this.firestore().collection(this.collectionName)
    const docRef = await collection.doc(id)
    const result = await docRef.delete()
  }

  async deleteBatch(idArray) {
    const collection = this.firestore().collection(this.collectionName)
    let batch = this.firestore().batch(),
      count = 0

    for (let id of idArray) {
      const doc = collection.doc(id)
      batch.delete(doc)
      count++

      if (count === 500) {
        await batch.commit()
        batch = this.firestore().batch()
        count = 0
      }
    }
    await batch.commit()
  }
}

export default FirestoreModel
